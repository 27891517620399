/**
 * 通用颜色
 */
.contact__content___1Aket {
  width: 100%;
  overflow: hidden;
  background-color: #ffcfe1;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
@media screen and (max-height: 700px) {
  .contact__content___1Aket {
    min-height: 116vh;
  }
}
@media screen and (min-height: 700px) {
  .contact__content___1Aket {
    min-height: 100vh;
  }
}
.contact__content___1Aket .contact__qrcode___1fPfA {
  width: 143px;
  margin-top: 24%;
  border-radius: 10px;
}
.contact__content___1Aket .contact__qrcode___1fPfA img {
  width: 100%;
}
@media (min-width: 420px) {
  .contact__content___1Aket .contact__qrcode___1fPfA {
    width: 160px;
    margin-top: 24.5%;
  }
}
@media (min-width: 390px) and (max-width: 400px) {
  .contact__content___1Aket .contact__qrcode___1fPfA {
    width: 152px;
    margin-top: 24.3%;
  }
}
@media (min-width: 400px) and (max-width: 420px) {
  .contact__content___1Aket .contact__qrcode___1fPfA {
    width: 156px;
    margin-top: 24.3%;
  }
}

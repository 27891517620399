.slick-prev,
.slick-next {
  display: none;
  width: 82px;
  height: 82px;
  padding: 30px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCBAMAAAAReh3bAAAAIVBMVEUAAADNx8HLxb/NxsHMxb/MxcDNxcDKxL/MxcDMxsDMxsBB7WiPAAAACnRSTlMAf4CPkJ+foO/v6ucRdwAAAGxJREFUSMdjYMABGDsMGPCDqFWL8CtgXbVqJSEjCKgAGrFqCSEjVhUQMmL5qBGjRowaQYERVoSMYOgiZARIxTL8KrSAtiTgVcG+iqAhVaOGjBoyaggNDVlE0BAC9T47QRVAQ5YQUMHSoQBhAABfCKugaC3hZQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 22px 22px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.slick-prev {
  left: -82px;
}
.slick-prev:hover,
.slick-prev:focus {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCBAMAAAAReh3bAAAAIVBMVEUAAADNx8HLxb/NxsHMxb/MxcDNxcDKxL/MxcDMxsDMxsBB7WiPAAAACnRSTlMAf4CPkJ+foO/v6ucRdwAAAGxJREFUSMdjYMABGDsMGPCDqFWL8CtgXbVqJSEjCKgAGrFqCSEjVhUQMmL5qBGjRowaQYERVoSMYOgiZARIxTL8KrSAtiTgVcG+iqAhVaOGjBoyaggNDVlE0BAC9T47QRVAQ5YQUMHSoQBhAABfCKugaC3hZQAAAABJRU5ErkJggg==);
  background-size: 30px 30px;
}
.slick-prev::before,
.slick-next::before {
  opacity: 0;
  content: '';
}
.slick-next {
  right: -66px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCBAMAAAAReh3bAAAAIVBMVEUAAADNx8HLxb/NxsHMxb/MxcDNxcDKxL/MxcDMxsDMxsBB7WiPAAAACnRSTlMAf4CPkJ+foO/v6ucRdwAAAGZJREFUSMftzLEJgEAUg+EIimtYX2V9o1g5grUTHG8MEcVMaWUnl0LskjL8fMCzpoyoL/MQRZCzLASSqJCeElmNGDHyI7JJ5KoXkyo6krsiuCjiNGHCxAciKwKhCIQikBSBtgyv/w1iOqu91pXZbwAAAABJRU5ErkJggg==);
  background-size: 22px 22px;
}
.slick-next:hover,
.slick-next:focus {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCBAMAAAAReh3bAAAAIVBMVEUAAADNx8HLxb/NxsHMxb/MxcDNxcDKxL/MxcDMxsDMxsBB7WiPAAAACnRSTlMAf4CPkJ+foO/v6ucRdwAAAGZJREFUSMftzLEJgEAUg+EIimtYX2V9o1g5grUTHG8MEcVMaWUnl0LskjL8fMCzpoyoL/MQRZCzLASSqJCeElmNGDHyI7JJ5KoXkyo6krsiuCjiNGHCxAciKwKhCIQikBSBtgyv/w1iOqu91pXZbwAAAABJRU5ErkJggg==);
  background-size: 30px 30px;
}

/**
 * 移动端通用变量
 */
.knowledge__knowledge_content___2Y9NS {
  position: relative;
  min-width: 288px;
  height: 100vh;
  padding: 0 16px;
  overflow: hidden;
  background-color: #ff6f61;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.knowledge__knowledge_content___2Y9NS .knowledge__flex_box___1hUwo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 110px);
}

/**
 * 通用颜色
 */
* {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  -moz-user-select: none;
       user-select: none;
  /* Standard syntax */
}
html {
  scroll-behavior: smooth;
}
.rank__scrollWrapper___BMQJD {
  padding-bottom: 20px;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.rank__rank_top___12aZj {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 270px;
  background-size: cover;
}
.rank__rank_top___12aZj .rank__rank_top_inner___2evKD {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
}
.rank__rank_top___12aZj .rank__user_ranking___2xH1J {
  float: right;
  margin: 12px;
  padding: 5px 11px;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}
.rank__rank_top___12aZj .rank__user_avatar___1nAWl {
  width: 88px;
  height: 88px;
  margin: 34px auto 0;
  background-size: cover;
  border-radius: 100%;
}
.rank__rank_top___12aZj .rank__user_name___17HZ4 {
  margin: 14px 10px 0;
  overflow: hidden;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  word-break: break-all;
}
.rank__rank_top___12aZj .rank__user_statistic___1M2C3 {
  position: relative;
  height: 66px;
  margin: 14px 20px 0;
  text-align: center;
}
.rank__rank_top___12aZj .rank__user_statistic_item___1rEVL {
  display: inline-block;
  width: 50%;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
}
.rank__rank_top___12aZj .rank__user_statistic_item___1rEVL::before {
  display: block;
  font-size: 12px;
  line-height: 17px;
  opacity: 0.8;
  content: attr(title);
}
.rank__rank_top___12aZj .rank__user_statistic_item___1rEVL span {
  position: relative;
  top: -6px;
  left: 20px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: -14px;
  background-size: cover;
}
.rank__rank_top___12aZj .rank__rank_divider___27f6f {
  position: absolute;
  top: 5px;
  left: 50%;
  width: 0;
  height: 50px;
  border: 1px solid #fff;
  opacity: 0.3;
}
.rank__rank_tab_list___3oFr0 {
  height: 44px;
  margin: 22px 20px 4px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 17px;
  line-height: 44px;
}
.rank__rank_tab_list___3oFr0 .rank__rank_tab_item___1n93o {
  position: relative;
  display: inline-block;
  margin-right: 24px;
}
.rank__rank_tab_list___3oFr0 .rank__rank_tab_item___1n93o:last-child {
  margin-right: 0;
}
.rank__rank_tab_list___3oFr0 .rank__rank_tab_item_active___2M-WZ {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 22px;
}
.rank__rank_tab_list___3oFr0 .rank__rank_tab_item_active___2M-WZ::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 22px;
  height: 6px;
  margin-left: -11px;
  background: linear-gradient(84.52deg, #ff5f5c 28.29%, #fe8a63 100%);
  border-radius: 4px;
  content: "";
}
.rank__rank_content___3HGni {
  padding: 0 20px 20px;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  color: #2e2e2e;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 70px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #efedec;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_item_left___1MK9R {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_ranking___2Wvsj {
  width: 26px;
  text-align: center;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_icon___RAc5j {
  width: 22px;
  height: 22px;
  margin: 2px;
  color: #fff;
  line-height: 22px;
  background-size: cover;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_avatar___37aAv {
  position: absolute;
  top: -5px;
  left: 20px;
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 100%;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_name___1Inbo {
  position: relative;
  padding-left: 60px;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_no_ranking___fDUkn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding-left: 40px;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_no_ranking___fDUkn .rank__rank_user_avatar___37aAv {
  left: 0;
}
.rank__rank_content___3HGni .rank__rank_user_item___doyb2 .rank__rank_user_valid_count___1RZQm {
  color: #918d89;
}
.rank__rank_content___3HGni .rank__rank_user_title___2LRxW {
  height: 17px;
  margin-top: 20px;
  color: #918d89;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  border-bottom: 0 none;
}
.rank__rank_content___3HGni .rank__empty_text___IC9vK {
  margin-top: calc((100vh - 340px) / 2 - 22px);
}
.rank__text_hidden_one___3Zn9c {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.rank__max_width___2A10o {
  width: auto;
  max-width: calc(100vw - 180px);
}
.rank__empty_text___IC9vK {
  width: 100%;
  height: 22px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.rank__is_bottom___3zxS- {
  padding-bottom: 20px;
  color: #918d89;
  font-size: 12px;
  text-align: center;
}

/**
 * 通用颜色
 */
.Card__content___ug1Xa {
  margin-bottom: 74px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 {
  position: relative;
  min-height: 550px;
  margin-bottom: 80px;
  padding-left: 590px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 .Card__avatar___1YK1d {
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
  height: 550px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 .Card__title___3Vmxe {
  margin-bottom: 14px;
  color: #484848;
  font-weight: 500;
  font-size: 36px;
  line-height: 50px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 .Card__price___RSkcJ {
  margin-bottom: 18px;
  color: #ff8576;
  font-size: 32px;
  line-height: 39px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 .Card__numberTitle___3tnZ- {
  margin-bottom: 10px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Card__content___ug1Xa .Card__card___2LpJ4 .Card__btn___3xHr4 {
  width: 152px;
  height: 44px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  background: #ff8576;
  border: none;
  border-radius: 6px;
}
.Card__content___ug1Xa .Card__numBox___2-Z1X {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 126px;
  height: 36px;
  margin-bottom: 60px;
  font-weight: 500;
  border: 1px solid #ccc6c0;
  border-radius: 4px;
}
.Card__content___ug1Xa .Card__numBox___2-Z1X .Card__calcul___2cfUP {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 34px;
  color: #918d89;
  font-size: 18px;
  cursor: pointer;
}
.Card__content___ug1Xa .Card__numBox___2-Z1X .Card__number___1tIMY {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 52px;
  color: #484848;
  font-size: 14px;
  line-height: 17px;
  border-right: 1px solid #ccc6c0;
  border-left: 1px solid #ccc6c0;
}
.Card__content___ug1Xa .Card__numBox___2-Z1X .Card__disabled___FaF2t {
  color: #efedec;
  cursor: not-allowed;
}
.Card__content___ug1Xa .Card__detailTitle___1tfPx {
  margin-bottom: 40px;
  color: #484848;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}

.LayoutIndex__content___sQK7F {
  width: 100%;
  min-width: 1032px;
  background: #fff;
}
.LayoutIndex__content___sQK7F .LayoutIndex__contentMain___3TXO- {
  position: relative;
  width: 1032px;
  margin: 0 auto;
  color: #484848;
}
.LayoutIndex__pageMain___2tFAc {
  min-width: 1200px;
}

/**
 * 通用颜色
 */
.Weike__contentMain___17vis .Weike__hiden___2WVza {
  display: none;
}
.Weike__contentMain___17vis .Weike__mainContTitle___2ZMf8 {
  padding: 110px 0 50px;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- {
  position: relative;
  height: 196px;
  margin-bottom: 50px;
  padding: 50px 80px 50px 430px;
  border-top: 1px solid #efedec;
  border-bottom: 1px solid #efedec;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__avatarBox___38-CY {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 80px;
  width: 300px;
  height: 196px;
  margin: auto;
  border-radius: 6px;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__recommendTitle___3ubcU {
  margin-bottom: 4px;
  padding-top: 25px;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__recommendDesc___1qQr0 {
  margin-bottom: 22px;
  color: #74706c;
  font-size: 22px;
  line-height: 31px;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__playBtn___BSt2Q {
  position: relative;
  padding: 12px 16px 12px 32px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  background: #ff8576;
  border: none;
  border-radius: 6px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__playBtn___BSt2Q::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  width: 12px;
  height: 14px;
  margin: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAMAAADs1AnaAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAECBAUGBwf4CPkJ+gr7/P3+9U+1baAAAAuklEQVQ4y62USxLEIAhEjSaaZDKKff/DziZTxh+wSK9fAV1AG2OWkJE+1nCyBAAAi90MizkUkZ9AO56icbEvah0jLDYQaOPmZgwMIOTQQBiqMYCJqp4zqDKAuUoxBioGwOo2wEPIXgEBQQPh0EDYNFDUQHgN2o1mJBkiq4DcO2uJ8oKzl08lykeXV/l8z0V8hOTk5/TyB8fuzVO3hrUPjIsbeBxi0YlxmGdx+EixYac6oied/tRFoJNDfnwZRUxxM094AAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  content: "";
}
.Weike__contentMain___17vis .Weike__recommendBox___2pmv- .Weike__playBtn___BSt2Q:hover {
  opacity: 0.7;
}
.Weike__caseBox___1M6Mk {
  width: 100%;
}
.Weike__caseItem___36nhu {
  width: 246px;
}
.Weike__caseItem___36nhu .Weike__caseTitle___F4B4s {
  min-height: 50px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.Weike__caseItem___36nhu:hover .Weike__layer___1DHMB {
  opacity: 1;
}
.Weike__caseItem___36nhu:hover .Weike__caseTitle___F4B4s {
  color: #ff8576;
}
.Weike__caseItem___36nhu .Weike__caseMsg___3J4Ci {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Weike__avatarBox___38-CY {
  width: 246px;
  height: 246px;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 6px;
}
.Weike__avatarBox___38-CY .Weike__layer___1DHMB {
  width: 100%;
  height: 100%;
  background: rgba(116, 112, 108, 0.2);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.Weike__avatarBox___38-CY .Weike__layer___1DHMB .Weike__clear___3mHyj {
  background: none;
  border: none;
  outline: none;
}
.Weike__avatarBox___38-CY .Weike__layer___1DHMB .Weike__caseIcon___BYg-E {
  width: 56px;
  height: 56px;
  cursor: pointer;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: 2px;
  background-color: #ff8576;
}

/* Fancy blur effect */
#nprogress .peg {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  -webkit-box-shadow: 0 0 10px #ff8576, 0 0 5px #ff8576;
          box-shadow: 0 0 10px #ff8576, 0 0 5px #ff8576;
  -webkit-transform: rotate(3deg) translate(0, -4px);
          transform: rotate(3deg) translate(0, -4px);
  opacity: 1;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1031;
  display: block;
}

#nprogress .spinner-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: solid 2px transparent;
  border-top-color: #ff8576;
  border-left-color: #ff8576;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.NineImg__wrap___1dK8Q {
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100%;
}
.NineImg__cell___3je33 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background: #ccc6c0;
}
.NineImg__cell___3je33 img {
  display: block;
  width: 100%;
  height: 100%;
}

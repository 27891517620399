/**
 * 通用颜色
 */
.contact__content___3UF9G {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (max-height: 700px) {
  .contact__content___3UF9G {
    min-height: 110vh;
  }
}
.contact__content___3UF9G .contact__header___3sVER {
  background-repeat: no-repeat;
  background-image: url(/_next/static/images/contact_bg-5e8506a067c775d97867c07756e8e33f.png);
  background-size: 100% auto;
  width: 100%;
  height: 230px;
}
.contact__content___3UF9G .contact__header___3sVER .contact__headerBox___2vrLf {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 148px;
}
.contact__content___3UF9G .contact__header___3sVER .contact__contactPerson___bw5uf {
  margin-bottom: 4px;
  font-family: "PingFang SC";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #2e2e2e;
  line-height: 22px;
}
.contact__content___3UF9G .contact__contactInfo___2bXwy {
  font-family: "PingFang SC";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #2e2e2e;
  line-height: 20px;
  margin-bottom: 4px;
}
.contact__content___3UF9G .contact__contentBox___3-tRh {
  margin: auto 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 12px;
}
.contact__content___3UF9G .contact__contentBox___3-tRh .contact__addressTitle___2t9cT {
  margin-bottom: 4px;
  font-family: "PingFang SC";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #2e2e2e;
  line-height: 22px;
  margin-top: 28px;
}
.contact__content___3UF9G .contact__contentBox___3-tRh .contact__addressDetail___1iwL6 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2e2e2e;
}
.contact__content___3UF9G .contact__contentBox___3-tRh .contact__map___270LR {
  height: 335px;
  margin-top: 32px;
}
.contact__content___3UF9G .contact__contentBox___3-tRh .contact__map___270LR img {
  width: 100%;
  height: 100%;
}

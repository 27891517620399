/**
 * 通用颜色
 */
.LayerA__layer___2J-Rr {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.LayerA__layer___2J-Rr .LayerA__clear___zDV5n {
  background: none;
  border: none;
  outline: none;
}
.LayerA__layer___2J-Rr .LayerA__close___3qyxU {
  position: fixed;
  top: 80px;
  right: 80px;
  width: 38px;
  height: 38px;
  padding: 20px;
  cursor: pointer;
}
.LayerA__main___36VbF {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 440px;
  padding-top: 80px;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 6px;
  -webkit-box-shadow: 0 30px 60px rgba(72, 72, 72, 0.1);
          box-shadow: 0 30px 60px rgba(72, 72, 72, 0.1);
}
.LayerA__main___36VbF .LayerA__qrcode___1sB8p {
  width: 170px;
  height: 170px;
  margin-bottom: 32px;
}
.LayerA__main___36VbF .LayerA__msg___32Z_Z {
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 22px;
  line-height: 31px;
}
.LayerA__main___36VbF .LayerA__desc___3jyWD {
  margin-bottom: 70px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.LayerA__show___gheWk {
  z-index: 999;
  opacity: 1;
}

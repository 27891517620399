/**
 * 移动端通用变量
 */
.Layer__layer___2R6ul {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
}
.Layer__layer___2R6ul > div {
  max-width: 600px;
  margin: 0 auto;
}
.Layer__layer___2R6ul.Layer__hide___2xbE_ {
  display: none;
}
.Layer__layer___2R6ul .Layer__banner___3OAS9 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 43px;
  margin: 20px 16px 12px;
  padding: 12px 24px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  background: #ff8576;
  border-radius: 99px;
}
.Layer__layer___2R6ul .Layer__banner___3OAS9 .Layer__timer___Lj0zs {
  margin: 0 4px;
  color: #f9fd50;
}
.Layer__layer___2R6ul .Layer__banner___3OAS9 .Layer__award___3DK8M {
  color: #fff;
  font-weight: 500;
}
.Layer__layer___2R6ul .Layer__pic_1___ccZIP {
  position: relative;
  left: 10%;
  width: 58.9%;
}
.Layer__layer___2R6ul .Layer__pic_2___3PRB3 {
  float: right;
  width: 44.8%;
  margin-right: 12.8%;
}
.Layer__layer___2R6ul .Layer__circle_wrapper___7Z6ah {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 0 22%;
}
.Layer__layer___2R6ul .Layer__circle___18pXJ {
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 70px;
  height: 70px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
}
.Layer__layer___2R6ul .Layer__circle___18pXJ::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 28px;
  margin: -14px 0 0 -15px;
  content: "";
}
.Layer__layer___2R6ul .Layer__circle___18pXJ.Layer__unlike___2mkWm::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABUCAMAAAA1fNBAAAAAPFBMVEUAAAD////////////////////////////////////////////////////////////////////////////YSWgTAAAAE3RSTlMA3yDvEJBwYEAwv4DPr59QsKAfiHMblQAAArdJREFUWMPtmNty2zAMRAneL6LUVP//r+1UUdBiLUYk1Tfvk+OZHHKBFUFZvfXWf5DVwa+VaN93s+QSn6Hqray0C9Gq57Ya/bqY/UKj8BRcpb0t47v9e/B/pXp/47Fks/eI9E1wbkGq81vSfzoQ8i221doen9xVSRfnoxVV+2iz7dkq8vo1mVa/6YuWnP1YvrFPWxBbrS4kq1oq+yEnd7xK2+x/9RtAW2zxaF50jHwCwrfsjF+iyKouVdy2/htH/Nn3ngQGtu0+y3q0Sn/VOXSB2b2Vla5Wlser7m3L/zu+SFAfp3rlZEWkjbMiP7rR8aisRPHfdLa0j8uoKFBJhOi3+tFVFLuKPLgT/bMb7Y+JI1BFnQonehssNl0ulYbTp6yIxMbH4Xj6sG+MMmo2fdg3DPYynj4n7Mr0fc0j240Owq50UcbSxxEgcIHpG40Io4bThzJsl1GLWJpX69HCdpvpW/qwHAGPLnhpXm1k0jhw8Xj60IU/0WkyfehiG5q82Dd2gQdU6ePyqQEuMH3TaCsHFg2nL4mCQPrqcPpWvi9A+ubGo+YT4uH0xc9KapjEc+nTIXP3IX2D49Gm4LLBtyVGkYgQr9Z8sYa31a0x5BlNzcoeW5Uqr4Z8hDljvhmxKLO9HPIrN/pUbOYMweV0LtPmYgquGnD3ShGwi4v2clyizC00VRd0R+Ew2YjGF2uUJWh0xowgOt95lv5l06Zi+8aQ7p+N+oMLnL39SqQBu/CY3YC7vCznmz6n282jUZmz/TQ6wnEzicZI5sfQGEn3GBofOd+4cYyqwEMJ14JhZWBDQSbLjew0jMYTwDfOkEEl8/Ls9jA8Z9hVQxO8eohNQc6vqGbZJH8CtzTdRTzNc0jKRuLWTrOXxi/WsyqNwTmrSJLs1GMqBOTHpAvBDWxOeIekdbPqrS79AlWlfZR4GlONAAAAAElFTkSuQmCC);
  background-size: cover;
}
.Layer__layer___2R6ul .Layer__circle___18pXJ.Layer__like___3_8XY {
  background: #ff8576;
  border-color: transparent;
  -webkit-box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
          box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
}
.Layer__layer___2R6ul .Layer__circle___18pXJ.Layer__like___3_8XY::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABUCAMAAAA1fNBAAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMA3+9gIIAQnzBwz0C/kFCvoB+wj2afCeIAAALASURBVFjD7ZjdtuIgDIWbhN+Wts6R93/WWeswGiG1WGDu3LddbpLwJYDTV41aLGDEcFejjT3Gh+xQcxXii2CgN2HMhG6Us46lQI12ZoWxzoHM5G4xaRngvD3q6//VfVjYCsrqrqPCFs6TgUR3f6ElET4VyHSWIyZlHBvkinSXw0+ZUmuufV0YuayiItBPh2w9k1b807+HJD7MXKb2PZSJM9qhO2glvywCP7WQ17RUgay3xhM/48jbHXhs0QU8Dsfn/vtpnjEK7apuLYIW+B0LXM3ZnU0hJQyvnEH2tC/gzBvMJ/WgD08enK3Vz+3Up85LsYkSnyTYV785wyvWp6KudIXSWtOiJFb1Rg2NvZzOzp/pRMhzui7ZqFX0cLoufDMV1UZa+yUtfn0A8VTc8lTu8xNM7ZuP1puA1oUopZvn5SovjP3WVCAiZ0LTycoAQG0k/DSfTchJ8ChY9c5zZuqmL5mhNsXswfa7i8sWomzh5vvAnpkln3yAMPkXtTJcbJ195bUvKu82KLL3TF8v2Nz3/BLgtZtm35z1vZcdNDeDDe/6voc+k/2Syt6DVvo4LtH3zGaS67PmHPrpkyjjO/p0R63H0+eYLaaPhtBH+bG6FvSZDvps7kWcfUGfuVxq5OKK+vTRR0VIqsz+1kofP1OG06fFjR+K7KmRvkWCtRfZuzb6FHDQxakj6YMGZ051GH0LHP2ftpXZz5fpM2kHuRyj6DMa30FVZv8MwX9SYx8wCudO+oyjO9uKUJroU460lW905DrX6UOTh6k2f98B46GCOr3zcPGT7q9hQjxR4JDP6FMvv7BWhCmFqzDOrdQj7z1eEAbhKxEB91tNi1U7fqOTq09DKYhS/H6gTX02Wg4C1Up6Q7CanLl4L2Yx0gTPMPfVb2rMH/e3FJrbiDjMRnkQkA6TooAxwmz9Zqavvvrf+guBlIMvPWCvoQAAAABJRU5ErkJggg==);
  background-size: cover;
}
.Layer__layer___2R6ul .Layer__btn___1xdDN {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 34.1%;
  height: 46px;
  margin: 24px auto 34px;
  padding: 11px;
  color: #ff6f61;
  line-height: 24px;
  text-align: center;
  border: 1px solid rgba(255, 111, 97, 0.4);
  border-radius: 30px;
}

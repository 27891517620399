/**
 * 移动端通用变量
 */
.allowance__main___1d_xm {
  padding: 0 16px 30px;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs {
  position: relative;
  padding-left: 90px;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  border-radius: 100%;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__name___236n9 {
  overflow: hidden;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL {
  display: inline-block;
  width: 87px;
  height: 25px;
  margin-top: 6px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  border-radius: 8px;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL::after {
  display: inline-block;
  -webkit-transform: scale(0.83);
          transform: scale(0.83);
  content: attr(data-name);
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL.allowance__level_1___381do {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQUAAABLCAMAAABObWILAAAAP1BMVEUAAAD31kv32U363U732U352k342k742k343E342k353Er33Ez32U3420343E3410z420342k342k352k332U1KiT6OAAAAFXRSTlMAEhcNOh81MRsqBQlCJi0jPkhMUVaNm35KAAAC7ElEQVR42u2ai5KrIAyG411BxNv7P+sJJW3GtVY93a0p+hEghNDRf7q2C4WLi4uLi4uLi4ugULFJu34cx6GvCxPBCVGmHqf0eQnnIs6HcQ4KoeE06HZc5Cw6qGYcpuCTAUP3aAUnIOro1tke+EGXQejYYQMNBI0qBkc/MBToJ/Eu5KeDavsbQ79GF0OooAjLDD/0MRAmKMIGQpeh6PYRpAy224uF4Mi67pJB13hXNdqGwk4GyyiNKPgq0pro7hULdWjTyl30VNHSNI/XawtTJvAd2HorpArR6tmmRNXWM9LqG7YndP2/pAqAiYp2MdGI/75Z1f+NhTsqa1+nVrJ1SNo3KO8apOu5VrIORfsGqWYNVsnl/v+R4K20aYrVmQPHFEvZa13rfc7CpgEoaXFLYVqFhrgR5xupH55N+h622JMtdM9Op58lF/mpaVMk33D1L6M52usFjMAtfZV/HIEnG1F+ACANk68TvAqqOAIQRlT8Mvk3qpAVmwj8vdAsUDR/iLRjHd1sIXQVIrykylnlHNdRoKLe0dwaSqAZyqRch5/ys+y54o1SXEDaiW9WHYE0FWy1hdBVqIwh4/JyiMwmpxncscvdzYQd6GhzCMJUSMwvYc0ehB3nRPYQhP0aJrZbCF6FbBHL3SzMgxXsc1/Y/kJMF2fZ7PzueHYy9EyjVNEsxXmKXWE70WV2APJU+AWy/cnC9l/dZWW+IcPKhacelaPZI/TosnkahzlL2iF2eQzCjiTiNcr4LxB2PvXzZkseMCVXTnrqlnP32csKezhCfAjCHo6kQoTFO972l33LhD0WIDoCaW+FY1QQ9jkJkEQfR95bAZIkSiasBDi2yGq2tKcCgPYXSeYb6qJ7kBM44ucnK9nm6RR1Vd7fA4BKPo1AEW4q6Lth4zsaMTw5DXPL80+Xsyfsa6NHfxC5P4zWLzmJCKCUXioe73PHsxxBni5XPyeEigDqc4jV4JUMJ9IAQLmC9l5ZSxAtwcWh/AOsA9AyFPiq5wAAAABJRU5ErkJggg==) #ffa34b;
  background-size: cover;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL.allowance__level_2___1nuh3 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQUAAABLCAMAAABObWILAAAAhFBMVEUAAAD/n1b/l1D/mlP9l1L/mVP/lk/7l1L+l1L9l1L9mFL8l1H/mFL/mVL9llL9l1L+l1H9mFL+mFH+mFP+mFL9l1H9l1L+l1L9l1L9l1L9l1L7l1H+l1L+l1L+mFH9l1L+mFH7llH9llH/mFL+l1L+mFP+mFL7l1L/mVT+lU/+llD9l1LGZhoGAAAAK3RSTlMACjASXCEZTvBjclUoN3qfkIiBl66m4rXby8RG+NO96Ws/az5/vejTRh0tyj/siQAAA0FJREFUeNrt22tzqjAQBuAFgly89WKt1lNUTI+1+f//7xC34LZDpws5H5bLI2YTaJzpKyiDCqOBUlkSwtApU1heYNCUQRoGTJnSeriHRWRu4giGyY/N1aCPChvC4GMoQyAUDE0ZAhUP7SWShECcYVCqEIb80oAh1IgDGIxrCH3aGUKVap1GYeMQ+rMzhLr6f84qdA0BpdAt4dJ8oQO3EFAGnaJi883adwoBxdAl2tRQTiEgH7pDm1qqcQhdPo3WrZ5JGkIP3iu1+ck6cAzBLKEjNO+p5IdAraAbSAj80x4aQh/eKjX3uOaHQK2hC3S75zKMDc8GOkCb3wS1IaxNj1LQ5ldRfQg9SkGb36nWISCQThsGXRdCj1LQhkPXhNCjFLRhOXwPYWN6lMLiD08EX1w2f5oByRYx0wdQl9e4GdEXGNL9/m+xFIrGdu0YO/ZuC9YtUJft3sLJtmBF+BgFOt6DXOma6w6Iycu6KcFn0IcN24SGkG0ak3vWlL+iM71/lnM5wvaBhvBcTrMNFupcTi7/4gqESrZ8ExLCatsGyJS88CU0hJdWQCSVZW/29pZl2EPF2N5sxdYODySEpwzhduxeG6toq81lBxuZV1nUM19KQlg+twQCTVd8B6gEj6uWJF53nD7x5SSEu6fWQJxoyZeQEO6Xrcm7Eh898ikSwsOjAxBmfsdHQ9B3LkAW/55vSkJY3LsQ9gmd/8BH94SUM0H/OJD1NY6PBV/i3ULIF25yEGSW8ikSQpI6kvTJ/SzPD/kBl9z2CzjGIZaiLZrpLQRP2TUWTilbq5p/wCGuqB7G3mwV9N34MOGjIUwTZ+8gRaj4IhJCpNzgwwkRTvl2UPHep852YkK4RHxHsifsImdHMSFMdrv364LFViw15lDxTsWYTqju1XrSrx/NQYrJqXA8HQtFc/rs2HW22m7hc51PQpif0HWKLVXFaXaAFSdjY/sFXCfnS37BnO+DhODPnfliDofA55vBzcx3NhMTgjfjC2kI7kI5IYR8E7i5hM7k/MjYm/AFcDP5D0AKL2gAbgJ3gn4b4jVAp7kT85IA4GHjlSO7kBHWciWif1o19lY2uIZsJ5vKjqAIRqPRaDQajTrkH48GykH5jCSxAAAAAElFTkSuQmCC) #ff8576;
  background-size: cover;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL.allowance__level_3___1wz14 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQUAAABLBAMAAACLnY8KAAAAHlBMVEUAAAD4+Pj////6+vrz8/Pw8PD39/f39/f6+vr7+/tMh4dlAAAACnRSTlMAJgYeEg4WCiIaoN4iawAABERJREFUaN68l71uwkAQhA9sMHQZ/uJ0RmkoQxEpJX6D5A1AKdJipUnJOVKSEt44UgqQPLun02LnqwEN++0OxrXMEAK1+1eWEMgLxySPheuGNSQeHNPDopsQA4gsHFMBM9cFI8hsHFF2tSgvkJnI1vK9a581ZHJHeKATGxk0jq5BnyS13A7MTIk7d22zhErzDlOqjpbYQeXQeOmYqsMOO46riOQiycbg1ePzLdRQzL5Rk5eLMfGOP74Kbiidmlbnmsv4OM+3oIbSmVJV06LEc48z39xQOhuqavNCZJ4+l1YyQsaJ5xNPqVbPEEGm8szyq6twQysZKYPay15Dt7SSOrXs7dk+Bp7kGvEyMh6PvY039NWiZKQ4s3Vx8FvpvGlEzESc59F8FHwZIyBeRmLOkIHIaSWjZCQ0yVhWYPbUOjEyetYMfQ9mSysZI2NpzTCGwIF+uGNkVNYMZWi+I0RQCx91c/1T85y+WYyMnTHDChIzrb2mZUDGyZjhFHxQ9DSgcUDG2pYhhcidtpIH53UZ3pZhGfwblwhHW+kyYMvggxk4YeGGqozMliGFwuXYaOqyDHuGKpzBCzery0htGZ5+FH7bL4PdpoEgDI/cCKtv0KOVAhU3VCOU3jikQdysEmO4VTQKyi0qGF+LIqFwq5oS1LdlZmTVm53JemJVPfVbu7X/nRn/no0dBYhYyNT0Z0IlHdl3hCv7UiyXv7TBHvhq9ekBDjo4r40feONPdw+fl8E+7Al5zq81JT7zwq92XgqJbnFRd09yTF8uXTz00q0AsfLVN6DozG+Uv6cNr8HIYbrAgSxwW/DxIGVOgECBNlIXvB87efXMgGYxN0MPfDgg1e5hlUocD7GQz+tXeCq5BvjYoQ9cSuctPxZCnteZF2rGRQcPe2m6Cnk4VLvjztylJ/eTWScPd9u5BeSDpjK9Wlg5kzcb9a6NS9HmYearN8DwlGDdxcN+wMNfnI+E2vyAe6XknHmhFr7MlELrNe//1D5lwR7e7u4hnq6nW5hNv9JjIXRomLbwDiz0AhXYw09fnEDDywfx8KnNw8gXv7kf6O4erN2k5dTEGuFQkJmWYhgCbyMW4kbd58MgczBANUbDyUgrMKHL9dxzJgGHuJmSjDDWwDBIpt0obDAOFgADcWsrf/jae7+RAU5NL+o8CHoQWubdRR4CDGDYqTfGJI155AlEeBb0ABy+MVjgWmfQTpSH0W4zMbZS+tWJ8zDaFXa5D8ujeVQ1lEVRVbgXVY4nVVmWRQ7wokSBtopkCgAf1DidZ/OiLCifwssSDGBsgYn4t8QsvlbzDwHgUy6PhSnoUjazDsEyDAWSCRGrElUhuIbQ5sYqMlYnbvUg6yfqiuqAgaMW+hBdOlSkgQCDdCxtiPotJErIDnXgYTwoksKW7Ef1oBcCC/2kHwU3ACGCipoOJiJIQpsWACpaus2ByBNlzIWV3Cdc/gP8H1HLxUH0bgAAAABJRU5ErkJggg==) #ff6f61;
  background-size: cover;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__info___1k2Cs .allowance__user_level___3puPL.allowance__level_4___1HGel {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQUAAABLCAMAAABObWILAAAAhFBMVEUAAAD/n1b/l1D/mlP9l1L/mVP/lk/7l1L+l1L9l1L9mFL8l1H/mFL/mVL9llL9l1L+l1H9mFL+mFH+mFP+mFL9l1H9l1L+l1L9l1L9l1L9l1L7l1H+l1L+l1L+mFH9l1L+mFH7llH9llH/mFL+l1L+mFP+mFL7l1L/mVT+lU/+llD9l1LGZhoGAAAAK3RSTlMACjASXCEZTvBjclUoN3qfkIiBl66m4rXby8RG+NO96Ws/az5/vejTRh0tyj/siQAAA0FJREFUeNrt22tzqjAQBuAFgly89WKt1lNUTI+1+f//7xC34LZDpws5H5bLI2YTaJzpKyiDCqOBUlkSwtApU1heYNCUQRoGTJnSeriHRWRu4giGyY/N1aCPChvC4GMoQyAUDE0ZAhUP7SWShECcYVCqEIb80oAh1IgDGIxrCH3aGUKVap1GYeMQ+rMzhLr6f84qdA0BpdAt4dJ8oQO3EFAGnaJi883adwoBxdAl2tRQTiEgH7pDm1qqcQhdPo3WrZ5JGkIP3iu1+ck6cAzBLKEjNO+p5IdAraAbSAj80x4aQh/eKjX3uOaHQK2hC3S75zKMDc8GOkCb3wS1IaxNj1LQ5ldRfQg9SkGb36nWISCQThsGXRdCj1LQhkPXhNCjFLRhOXwPYWN6lMLiD08EX1w2f5oByRYx0wdQl9e4GdEXGNL9/m+xFIrGdu0YO/ZuC9YtUJft3sLJtmBF+BgFOt6DXOma6w6Iycu6KcFn0IcN24SGkG0ak3vWlL+iM71/lnM5wvaBhvBcTrMNFupcTi7/4gqESrZ8ExLCatsGyJS88CU0hJdWQCSVZW/29pZl2EPF2N5sxdYODySEpwzhduxeG6toq81lBxuZV1nUM19KQlg+twQCTVd8B6gEj6uWJF53nD7x5SSEu6fWQJxoyZeQEO6Xrcm7Eh898ikSwsOjAxBmfsdHQ9B3LkAW/55vSkJY3LsQ9gmd/8BH94SUM0H/OJD1NY6PBV/i3ULIF25yEGSW8ikSQpI6kvTJ/SzPD/kBl9z2CzjGIZaiLZrpLQRP2TUWTilbq5p/wCGuqB7G3mwV9N34MOGjIUwTZ+8gRaj4IhJCpNzgwwkRTvl2UPHep852YkK4RHxHsifsImdHMSFMdrv364LFViw15lDxTsWYTqju1XrSrx/NQYrJqXA8HQtFc/rs2HW22m7hc51PQpif0HWKLVXFaXaAFSdjY/sFXCfnS37BnO+DhODPnfliDofA55vBzcx3NhMTgjfjC2kI7kI5IYR8E7i5hM7k/MjYm/AFcDP5D0AKL2gAbgJ3gn4b4jVAp7kT85IA4GHjlSO7kBHWciWif1o19lY2uIZsJ5vKjqAIRqPRaDQajTrkH48GykH5jCSxAAAAAElFTkSuQmCC) #ff4d3d;
  background-size: cover;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__btn___1V2aY {
  width: 56px;
  height: 31px;
  color: #fff;
  font-size: 13px;
  line-height: 31px;
  text-align: center;
  background: #ff8576;
  border-radius: 8px;
}
.allowance__main___1d_xm .allowance__user_box___2vFNp .allowance__btn___1V2aY.allowance__disabled___10fCS {
  background: #fee5e2;
}
.allowance__main___1d_xm h4 {
  margin: 30px 0 26px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.allowance__main___1d_xm .allowance__progress___3na2P {
  position: relative;
  height: 10px;
  margin: 26px 0 14px;
  background: #f5f4f4;
  border-radius: 9px;
}
.allowance__main___1d_xm .allowance__progress___3na2P p {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 7px;
}
.allowance__main___1d_xm .allowance__progress___3na2P p::after {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 32px);
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(1.04%, #fed670), color-stop(50.6%, #ffa149), color-stop(101.19%, #ff6f61));
  background: linear-gradient(90deg, #fed670 1.04%, #ffa149 50.6%, #ff6f61 101.19%);
  content: "";
}
.allowance__main___1d_xm .allowance__level___3TpMr {
  overflow: hidden;
  color: #74706c;
  font-size: 13px;
  line-height: 18px;
}
.allowance__main___1d_xm .allowance__level___3TpMr em {
  margin: 0 5px;
  font-weight: bold;
  line-height: 16px;
}
.allowance__main___1d_xm .allowance__cash_box___50M_o {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 24px 0;
  padding: 20px 0 24px;
}
.allowance__main___1d_xm .allowance__cash_box___50M_o::before,
.allowance__main___1d_xm .allowance__cash_box___50M_o::after {
  position: absolute;
  left: -50%;
  width: 200%;
  height: 0;
  border-bottom: 1px solid #efedec;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  content: "";
}
.allowance__main___1d_xm .allowance__cash_box___50M_o::before {
  top: 0;
}
.allowance__main___1d_xm .allowance__cash_box___50M_o::after {
  bottom: 0;
}
.allowance__main___1d_xm .allowance__cash_box___50M_o .allowance__allowance___26cZl {
  width: 50%;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}
.allowance__main___1d_xm .allowance__cash_box___50M_o .allowance__allowance___26cZl span {
  display: block;
  margin-bottom: 4px;
  color: #484848;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
}
.allowance__main___1d_xm h6 {
  position: relative;
  margin-top: 40px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.allowance__main___1d_xm h6::before,
.allowance__main___1d_xm h6::after {
  position: absolute;
  top: 10px;
  width: 31.5%;
  height: 0;
  border-bottom: 1px solid #efedec;
  content: "";
}
.allowance__main___1d_xm h6::before {
  left: 16px;
}
.allowance__main___1d_xm h6::after {
  right: 16px;
}
.allowance__main___1d_xm .allowance__rule___XQU3E {
  margin-top: 18px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
}
.allowance__main___1d_xm .allowance__more_btn___1Q9Ou {
  height: 52px;
  margin-top: 76px;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  line-height: 52px;
  text-align: center;
  background: #ff8576;
  border-radius: 8px;
}

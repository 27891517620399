.new__main___2NZEr {
  position: relative;
  padding: 10px 20px 40px;
}
.new__brand___Ynx88 {
  position: absolute;
  top: 24px;
  left: 20px;
  width: 70px;
  height: 21px;
}
.new__empty___2iEYr {
  padding: 100px 0;
  text-align: center;
}
.new__info___3bOJD {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 150px 0 0;
  color: #2e2e2e;
  font-weight: bold;
  font-size: 22px;
}
.new__info___3bOJD img {
  width: 88px;
  height: 88px;
  margin-bottom: 16px;
  border-radius: 100%;
}
.new__btn___QEP_e {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 150px;
  height: 58px;
  margin-top: 50px;
  color: #fff;
  font-size: 18px;
  background-color: #ff6f61;
  border-radius: 100px;
  cursor: pointer;
}
.new__copyright___1yJMI {
  position: fixed;
  right: 0;
  bottom: 50px;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #ccc6c0;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

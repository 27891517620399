.category__main___24T3p {
  width: 650px;
}
.category__fixedQrcode___34LXN {
  position: fixed;
  top: 232px;
  left: 50%;
  z-index: 666;
  margin-left: 254px;
}
.category__pagination___1U-Bc {
  margin-bottom: 100px;
}

/**
 * 移动端通用变量
 */
.Rule__rule___3dUmP {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  color: #484848;
  font-size: 16px;
}
.Rule__ruleInfo___2OMLM {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 300px;
  height: 300px;
  margin: auto;
  padding: 48px 25px;
  background-color: #fff;
  border-radius: 14px;
}
.Rule__ruleInfo___2OMLM p {
  margin-bottom: 50px;
}
.Rule__ruleInfo___2OMLM h3 {
  margin-bottom: 12px;
  font-size: 18px;
  text-align: center;
}
.Rule__ruleInfo___2OMLM ul {
  padding-left: 0;
}
.Rule__ruleInfo___2OMLM li {
  white-space: pre-line;
  word-break: break-word;
  list-style: none;
}
.Rule__mask___1YIgz {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

/**
 * 通用颜色
 */
.Card__contentMain___hlMvf {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 370px;
  margin-bottom: 80px;
  padding-left: 400px;
}
.Card__contentMain___hlMvf .Card__avatar___g3ayi {
  position: absolute;
  top: 0;
  left: 0;
  width: 370px;
  height: 370px;
}
.Card__contentMain___hlMvf .Card__title___uld88 {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
}
.Card__contentMain___hlMvf .Card__tags___2rWjD {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: 6px;
}
.Card__contentMain___hlMvf .Card__tags___2rWjD .Card__tag___1_8q_ {
  position: relative;
  margin-right: 20px;
  padding-bottom: 7px;
  padding-left: 23px;
  color: #918d89;
  font-size: 12px;
  line-height: 17px;
}
.Card__contentMain___hlMvf .Card__tags___2rWjD .Card__tag___1_8q_::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAtFBMVEUAAAD/gHD/39//79//gHj/h3D/h3j/5+f/hXD/hXX/6uT/6ur/g3T/6+f/6+v/7ev/g3b/6+n/hnb/v7j/6+r/7ev/g3X/vbb/7Oj/hHX/hnX/7Or/hXb/7er/hXb/7er/hXb/7Or/7ev/hXb/i33/jH3/jH7/koT/lIf/mIv/nJD/n5P/sqj/s6j/s6n/ubD/wrr/wrv/ysP/0cz/0s3/2dT/39v/5eP/7Or/8O7/+Pf///8TAcfoAAAAI3RSTlMAEBAQICAgIDAwMDBAQEB/gICPj4+PkJCQv7+/z8/f3+/v745LJLwAAAEbSURBVDjLxdNtW4IwFAZgoBd7IVPLFQJaHctqmmGQq/3//xVsOM7ZxueeT3BxX2eH7SwIrAyv04UQT+ntZeDPMBUm6ZUHRFNBMj2yxSAXVvKBVSMvt3wF8MzXlTG0zg0HE14e1kIgnADJZq9N1/NJBlbevvV/mRqOqM2elJmAJxtFxlrE4E2JVpr5CW/IQokz69N7VaAyijBL/Er5qp62hswc8dOt9KjIgyN0EViZdtuPL8UHFQA1ucdkJ2VBRUMuFJnr908pJRVLZ1u+aoNF0267/yPoDBawbjvB+7+rsIAqjw4H3XcAaDB7jvEuQjPHfIKRufWNVBbS8T91THZsX6LQWouFnrsYJx1I4p4bfc6S+tDnyagP/Hv+AFUka4PqwoQXAAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  content: "";
}
.Card__contentMain___hlMvf .Card__pricebox___2AQuH {
  margin-bottom: 22px;
}
.Card__contentMain___hlMvf .Card__pricebox___2AQuH span {
  display: inline-block;
  vertical-align: middle;
}
.Card__contentMain___hlMvf .Card__pricebox___2AQuH .Card__unit___1jMgb {
  margin-right: 2px;
  padding: 2px 0 0 2px;
  color: #ff8576;
  font-weight: bold;
  font-size: 24px;
  font-family: "Arial", "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 28px;
}
.Card__contentMain___hlMvf .Card__pricebox___2AQuH .Card__price___2e8_V {
  margin-right: 11px;
  padding-bottom: 1px;
  color: #ff8576;
  font-size: 24px;
  line-height: 29px;
}
.Card__contentMain___hlMvf .Card__pricebox___2AQuH .Card__original___24R1h {
  padding: 7px 0 4px;
  color: #b0a9a3;
  font-size: 16px;
  line-height: 19px;
  text-decoration: line-through;
}
.Card__contentMain___hlMvf .Card__btns___YlS9R .Card__assistant___1Qt9l {
  position: relative;
  height: 44px;
  padding: 0 10px 0 35px;
  color: #ff8576;
  background: #ffffff;
}
.Card__contentMain___hlMvf .Card__btns___YlS9R .Card__assistant___1Qt9l::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 20px;
  margin: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAA8CAMAAAD7a6zYAAAAbFBMVEUAAAD/gHD/hHv/gHD/gHj/h3D/h3j/hXD/hXX/g3T/g3P/g3b/hnb/gnX/hXX/iHX/hHT/hHb/h3b/g3b/hHb/hnf/g3X/hXX/hXf/hHb/hXb/hnb/hXf/hHX/hnX/hHb/hXb/hXb/hXb/hXZVe6rDAAAAI3RSTlMAEB8gICAgMDBAUFBfYGBvcHB/gI+PkJ+foK+vsL+/z8/f7+nuf+kAAALBSURBVFjDrZfrtqIgFIBlrEbqDMejRVqjI/D+7zgJyM2tblvxJ1bIx76zyTJg5Oz6FFIpJYf+esmz9wethIqHuL7Jo72CRs8+htLiXfahyFOtjWaPstTbSnac0SKnlNWd9MJRNOvbkXi8ibbulArJqiZUTeaLTOyiTSwINY56B82yRLH4xUlgacx82BKEr782WEdzKt/4zKgqNyKkR7GyrDXZgFByQJjWnLqaWlpJcUDATjqCpTEtHxpGYMFwmXw28WOCGcoxLdgDGdtaURlsjHH6AHXAJrD+moaBLAKlmvGPDp3CWrQmAEeBLPEWcwSjJ+lSWrCIK3rS65md2phWjvO/O8reY9zwk6bsBVhDjNIbzYSb8rHXB1K/RsHcnLLCT/M41MJ0MbTGBcuvsBTdZtMm9NhJh8M8ycbj9CSOIubz4uKnTuJoh08yni7VXuJA+DYuKjOY2TZaLVoq/Tbup22QkCDMhYv+JZHARZJlccKROcwcx1MHnIWSP35qE6N8Td2F/nvmAGvV3sZZcPXnW9MzkMuThzmuYifWTjZMqrPtsg6UjbQwWJiWUBE066icj+Yw44EaDWOA/Y1T5GQDgYaJJM9jp1CV+BNxLaZFvnFO0RYVZIdgHfjvxQf4bUezxEBxSVBCMG1hoSADH0M7GtHEdstqGxzQYk4YjqMtNEuVihxMBIZmWQLuEw9JrVR/Vu1lWPIAtoD17NZfg33bFp5CLVuiersOc0+XICoIcbA2w8McaqqW2VOMr77K7ntmKzAa6EJo5d4orhmfGn3BQCGCP7Ucw7N+jet98K8d1ZH0MfOSdRW2+LCTrof4SpcWYMso/3Qp/qFgNwTK2JEyRt2DCoYtPFv5Ug2o5TZM1jkt+b3rHndentcKnW32lmGyJjvuKp1kS7B9KPsWhWH7UVpXCPYWCn6wfQz1KrafQP0HS12r/gnNRiQAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  content: "";
}
.Card__mode___27NNJ {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Card__bottom___60ZR3 {
  margin-bottom: 40px;
}
.Card__bottom___60ZR3 a {
  text-decoration: none;
}
.Card__bottom___60ZR3 .Card__shopsTitle___3kiBF {
  margin-bottom: -4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Card__bottom___60ZR3 .Card__btn___38uOy {
  color: #918d89;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.Card__shop___Tg3Iw {
  padding: 16px 0;
  color: #484848;
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px solid #efedec;
  cursor: pointer;
}
.Card__shop___Tg3Iw:last-child {
  border-bottom: none;
}
.Card__shop___Tg3Iw span {
  color: #484848;
}

/**
 * 通用颜色
 */
.ServicePackage__contentMain___3nKq- .ServicePackage__mainContTitle___1RFwY {
  padding: 110px 0 50px;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 246px;
  height: 328px;
  margin: 0 16px 50px 0;
  overflow: hidden;
  cursor: pointer;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__headimg___36pbY {
  width: 246px;
  height: 200px;
  margin-bottom: 16px;
  border-radius: 6px;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__tagsBox___21Utb {
  height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__tag___1-gX- {
  display: inline-block;
  margin: 0 5px;
  padding: 0 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  background: #ff8576;
  border-radius: 6px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__caseTitle___2KIQf {
  min-height: 44px;
  margin-bottom: 4px;
  padding: 0 4px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__casePrice___3skzL {
  margin-bottom: 3px;
  padding: 0 4px;
  color: #ff8576;
  font-weight: 600;
  font-size: 16px;
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 19px;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__casePrice___3skzL span {
  margin-right: 2px;
  padding-top: 4px;
  padding-bottom: 1px;
  font-size: 12px;
  font-family: "Arial", "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 14px;
}
.ServicePackage__contentMain___3nKq- .ServicePackage__caseItem___2ZytF .ServicePackage__caseSort___3eTa9 {
  padding: 0 4px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.ServicePackage__caseBox___21Nzy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1048px;
  margin-bottom: -50px;
}

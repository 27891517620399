/**
 * 通用颜色
 */
.WeChat__qrcodeMain___17r9O {
  position: absolute;
  bottom: 41px;
  left: 50%;
  z-index: 888;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 144px;
  height: 169px;
  margin-left: 546px;
  padding-top: 16px;
  background: #ffffff;
  border: 1px solid #efedec;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.WeChat__qrcodeMain___17r9O .WeChat__qrcode___3QnPY {
  display: block;
  width: 112px;
  height: 112px;
  margin: 0 auto 9px;
}
.WeChat__qrcodeMain___17r9O .WeChat__qrcodeMsg___KdSV5 {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  color: #ff8576;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.09em;
  white-space: pre-line;
  text-align: center;
}
.WeChat__lessThan1320___mzZ0P {
  right: 30px;
  left: auto;
  margin-left: 0;
}
.WeChat__fixedMain___1jJ1Q {
  position: fixed;
  top: 30px;
}

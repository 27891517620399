/**
 * 移动端通用变量
 */
.customer__content___3lb28 {
  padding: 40px 16px 106px;
}
.customer__content___3lb28 .customer__title___94dAi {
  margin-bottom: 18px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.customer__userInfo___1gWyS {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 22px;
}
.customer__userInfo___1gWyS .customer__avatar___30pHj {
  width: 86px;
  height: 86px;
  border-radius: 50%;
}
.customer__userInfo___1gWyS .customer__right___1OHiE {
  padding-left: 20px;
}
.customer__userInfo___1gWyS .customer__right___1OHiE .customer__name___1tvP4 {
  max-width: 200px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.customer__userInfo___1gWyS .customer__right___1OHiE .customer__link___3f5pm {
  position: relative;
  margin-right: 30px;
  padding: 7px 12px 7px 0;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
}
.customer__userInfo___1gWyS .customer__right___1OHiE .customer__link___3f5pm:last-child {
  margin-right: 0;
}
.customer__userInfo___1gWyS .customer__right___1OHiE .customer__link___3f5pm::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  height: 12px;
  margin: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAkCAMAAACzM5rVAAAAPFBMVEUAAADPv7/Pz7/Hv7/Hx7/Kxb/Lw7/Mxr/Nx8HLxb/NxsHMxb/Mx8DNxcDNx8DKxL/MxsDMxcDMxsDMxsAQ0O+ZAAAAE3RSTlMAEBAgIDBAUH+Aj5Cfn5+g3+/vcWEDAwAAAGZJREFUKM/V0kkOgCAMQNEilNEB7f3vquCmwwW0y5fwm5ACACKoqURVCtIz2RJFYcegy3NyfVh33MI5bBNPI9kV5acrxIcmQ37GdptfGK1TApM8Q8mky6fTaNIvFXlgjajpM9SHeQPYrA4UyARdugAAAABJRU5ErkJggg==);
  background-size: 100% 100%;
  content: "";
}
.customer__infoBox___3zFVW {
  margin-bottom: 40px;
}
.customer__infoBox___3zFVW .customer__card___2fF1B {
  position: relative;
  padding: 18px 0;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__remark___3got8,
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__val___2KC2W {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  vertical-align: middle;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__remark___3got8 {
  margin-right: 20px;
  color: #b0a9a3;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__tagRemark___3hwQ- {
  vertical-align: top;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__val___2KC2W {
  margin-right: 30px;
  color: #484848;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__maxWidth60___2FUsx,
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__maxWidth200___3g8aW {
  max-width: 16%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__maxWidth200___3g8aW {
  max-width: 53%;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__btn___2OUb0 {
  position: absolute;
  top: 18px;
  right: 0;
  color: #ff8576;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__tags___l3vsD {
  display: inline-block;
  max-width: 59%;
  vertical-align: top;
}
.customer__infoBox___3zFVW .customer__card___2fF1B .customer__tagItem___3nXDs {
  display: inline-block;
  margin: 2px 10px 2px 0;
  padding: 0 6px;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  vertical-align: middle;
  background: #ff8576;
  border-radius: 6px;
}
.customer__footer___1Tn93 {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 666;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 16px 16px 10px;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  background: #fff;
  border-top: 1px solid #efedec;
}
.customer__btnsBox___2kcXD {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 20px;
}
.customer__cardTextarea___2H0hA {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.customer__cardTextarea___2H0hA .customer__textareaTitle___3LlJC {
  max-width: 159px;
  height: 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 60px;
}
.customer__cardTextarea___2H0hA .customer__textareaBox___3lREO {
  min-height: 4em;
}
.customer__inputBox___1TvUv {
  margin-bottom: 16px;
}
.customer__addressBox___2_hJt {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #efedec;
}
.customer__addressBox___2_hJt:last-child {
  border-bottom: none;
}
.customer__addressBox___2_hJt .customer__addressVal___2ZcX4,
.customer__addressBox___2_hJt .customer__addressInput___2sc5e {
  padding-right: 36px;
  color: #484848;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  border: none;
}
.customer__addressBox___2_hJt .customer__addressMsg___248I7 {
  padding-top: 6px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.customer__addressBox___2_hJt .customer__addressBtn___3d1VK {
  position: absolute;
  top: 20px;
  right: 0;
  color: #ff8576;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.customer__addressBox___2_hJt .customer__addressInput___2sc5e {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}
.customer__clueStepBox___2T-PL {
  max-height: 207px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.customer__clueStepBox___2T-PL .customer__recordItem___3UKc2 {
  padding: 17px 0;
  border-bottom: 1px solid #efedec;
}
.customer__clueStepBox___2T-PL .customer__recordItem___3UKc2 .customer__recordInfo___2FClo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
}
.customer__clueStepBox___2T-PL .customer__recordItem___3UKc2 .customer__textLight___2BYHT {
  color: #ff8576;
}
.customer__clueStepBox___2T-PL .customer__recordItem___3UKc2 .customer__status___2e4Jn {
  padding-top: 8px;
  color: #b0a9a3;
  font-size: 12px;
  line-height: 17px;
}
.customer__recordsBox___3UIIK {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 60px;
  font-size: 14px;
}
.customer__recordsBox___3UIIK .customer__recordsMsg___3mI3x {
  width: 33%;
  font-weight: 500;
}
.customer__recordsBox___3UIIK .customer__recordsItemBox___ewHC7 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 66%;
  padding: 16px 0;
}
.customer__recordsItem___32gAo {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px 0;
}
.customer__recordsItem___32gAo .customer__recordsTitle___1xEq0 {
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.customer__recordsItem___32gAo .customer__recordsBtn___3Tsf7 {
  color: #ff8576;
}
.customer__appointBox___2xeBn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}
.customer__appointBox___2xeBn .customer__appointTitle___2cqYO {
  font-weight: 500;
  font-size: 14px;
}
.customer__appointBox___2xeBn .customer__select___3-HhE {
  width: 70%;
}

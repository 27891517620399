.markdown h1 {
  font-size: 22px;
  line-height: 31px;
}
.markdown h2 {
  font-size: 18px;
  line-height: 25px;
}
.markdown h1,
.markdown h2 {
  margin-bottom: 20px;
  font-weight: 500;
}
.markdown h3 {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.markdown p {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.markdown img {
  width: 100%;
  margin-bottom: 20px;
}
.markdown ul,
.markdown ol {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 26px;
  list-style-position: inside;
}
.markdown ul li {
  list-style-type: disc;
}
.markdown ol li {
  list-style-type: decimal;
}
.markdown a {
  color: #ff8576;
}
.markdown blockquote {
  position: relative;
  display: block;
  -webkit-margin-before: 30px;
          margin-block-start: 30px;
  -webkit-margin-after: 30px;
          margin-block-end: 30px;
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-end: 80px;
          margin-inline-end: 80px;
  color: #918d89;
  font-size: 16px;
  line-height: 26px;
}
.markdown blockquote::after {
  position: absolute;
  top: 6px;
  right: -41px;
  width: 17px;
  height: 14px;
  background: url('/static/common/blockquote.png');
  background-size: 100% 100%;
  content: '';
}
.markdown strong {
  font-weight: 500;
}

/**
 * 移动端通用变量
 */
.content__content___3qck6 {
  padding: 50px 30px 38px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
.content__content___3qck6 .content__header___1cK05 {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.content__content___3qck6 .content__header___1cK05::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #efedec;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  content: "";
}
.content__content___3qck6 .content__header___1cK05 .content__title___1198_,
.content__title___1198_ {
  margin-bottom: 20px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}
.content__content___3qck6 .content__header___1cK05 .content__headerMsg___GErVP {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}
.content__content___3qck6 .content__introduction___zOHLo {
  margin-bottom: 60px;
  color: #2e2e2e;
  font-size: 17px;
  line-height: 32px;
}
.content__section___2IFxr {
  margin-bottom: 60px;
  color: #2e2e2e;
}
.content__section___2IFxr .content__articleTitle___3QOoN {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 19px;
  line-height: 27px;
}
.content__section___2IFxr .content__summarize___2FBl9 {
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 32px;
}
.content__section___2IFxr .content__sectionTitle___1sVmH {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.content__section___2IFxr .content__textBold___Wl6Hk {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.content__section___2IFxr .content__sectionUl___SshUD {
  margin-bottom: 20px;
}
.content__section___2IFxr .content__sectionUl___SshUD .content__sectionLi___kMfkk {
  font-size: 17px;
  line-height: 32px;
}
.content__section___2IFxr .content__highlight___1rGZ_ {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
}
.content__section___2IFxr .content__highlight___1rGZ_ em {
  background: #fee5e2;
}
.content__section___2IFxr .content__codeBox___1A2NC {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 138px;
  padding: 29px 140px 0 26px;
  border: 1px solid #efedec;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 0 rgba(239, 237, 236, 0.5);
          box-shadow: 0 4px 0 rgba(239, 237, 236, 0.5);
}
.content__section___2IFxr .content__codeBox___1A2NC .content__codeTitle___2TGoz {
  display: -webkit-box;
  margin-bottom: 10px;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.content__section___2IFxr .content__codeBox___1A2NC .content__codeMsg___nSE4P {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.content__section___2IFxr .content__codeBox___1A2NC .content__qrcode___6fhPl {
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  width: 90px;
  height: 90px;
  margin: auto;
}
.content__player___3CKST {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  -webkit-box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.06);
          box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.06);
}

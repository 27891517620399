/**
 * 通用颜色
 */
.Product__content___1Y18D {
  position: relative;
}
.Product__content___1Y18D .Product__list___1KV15 {
  margin-bottom: 21px;
}
.Product__content___1Y18D .Product__item___3RN0g {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 201px;
  padding-top: 40px;
  padding-left: 142px;
  overflow: hidden;
  border-bottom: 1px solid #efedec;
}
.Product__content___1Y18D .Product__item___3RN0g:last-child {
  border-bottom: none;
}
.Product__content___1Y18D .Product__item___3RN0g .Product__avatar___1n62K {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 6px;
  cursor: pointer;
}
.Product__content___1Y18D .Product__main___3WJ2r {
  position: relative;
  height: 120px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__title___fGmWw {
  width: 295px;
  margin-bottom: 8px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__tags___1-NOS {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__tags___1-NOS .Product__tag___1Xpn9 {
  margin: 0 6px 6px 0;
  padding: 0 6px;
  color: #ff8576;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  background: #fff3ed;
  border-radius: 3px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd {
  position: absolute;
  bottom: 0;
  left: 0;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd p {
  display: inline-block;
  vertical-align: middle;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__price___35k9z {
  margin-right: 7px;
  color: #ff8576;
  font-weight: 500;
  font-size: 18px;
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 21px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__price___35k9z span {
  margin-right: 2px;
  padding-top: 2px;
  font-family: "Arial", "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 19px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__originalPrice___1k5Q1 {
  margin-right: 15px;
  color: #918d89;
  font-size: 13px;
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 16px;
  text-decoration: line-through;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__originalPrice___1k5Q1 span {
  margin-right: 1px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__num___3HJnN {
  color: #ccc6c0;
  font-weight: 500;
  font-size: 13px;
  font-family: "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 18px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__footer___3cgTd .Product__num___3HJnN span {
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__score___158nV {
  position: absolute;
  top: 0;
  right: 0;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__score___158nV div {
  display: inline-block;
  vertical-align: middle;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__score___158nV .Product__line___3wiBh {
  width: 1px;
  height: 30px;
  background: #f5f4f4;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__score___158nV .Product__total___3Q8TO {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 30px;
  margin-left: 20px;
  padding: 0 5px;
  color: #ff8576;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  background: #ffecea;
  border-radius: 7px;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__btn___Rh-3P {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 84px;
  height: 38px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  background: #ff8576;
  border-radius: 6px;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.Product__content___1Y18D .Product__main___3WJ2r .Product__btn___Rh-3P:hover {
  opacity: 0.7;
}
.Product__content___1Y18D .Product__scoreBox___z35Oi {
  margin-left: 10px;
}
.Product__content___1Y18D .Product__scoreItem___IAvAE {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.Product__content___1Y18D .Product__scoreItem___IAvAE .Product__msg___3uQWq {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.Product__content___1Y18D .Product__scoreItem___IAvAE .Product__num___3HJnN {
  margin-left: 6px;
  color: #ff8576;
  font-size: 14px;
  line-height: 17px;
}
.Product__content___1Y18D .Product__noContent___2LQ7k {
  padding: 150px 0;
  color: #ccc6c0;
  text-align: center;
}

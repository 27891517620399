/**
 * 移动端通用变量
 */
.book__cover___1Omuv {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #f1f2f4;
}
.book__cover___1Omuv::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 375px;
  height: 375px;
  background: url(/_next/static/images/bg-book-e4e57903b7633a4a6dba5d74944f8e8b.png);
  background-size: 100% 100%;
  content: "";
}
.book__cover___1Omuv::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 375px;
  height: 300px;
  background: url(/_next/static/images/bg-book-bottom-d69dc05ac7ad393e98baac82400400c7.png);
  background-size: 100% 100%;
  content: "";
}
.book__cover___1Omuv .book__logo___1rv7J {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 74px;
  height: 22px;
}
.book__cover___1Omuv .book__coverTitle___2uwX- {
  position: relative;
  z-index: 555;
  padding: 318px 16px 12px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
}
.book__cover___1Omuv .book__coverMsg___ii484 {
  position: relative;
  z-index: 555;
  margin-bottom: 65px;
  color: #918d89;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
}
.book__cover___1Omuv .book__coverTitle___2uwX-,
.book__cover___1Omuv .book__coverMsg___ii484 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book__cover___1Omuv .book__coverBtn___13dKE {
  position: relative;
  z-index: 555;
  display: block;
  width: 108px;
  height: 44px;
  margin: 0 auto;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  background: #ff8576;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 0 15px 15px rgba(255, 133, 118, 0.15);
          box-shadow: 0 15px 15px rgba(255, 133, 118, 0.15);
}
.book__copyright___1MOfy {
  position: absolute;
  right: 0;
  bottom: 16px;
  left: 0;
  margin: auto;
  color: #ccc6c0;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
.book__directory___1txGT {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 100vh;
  padding: 20px 20px 83px;
}
.book__directory___1txGT .book__joinGroup___3Kya3 {
  position: relative;
  margin-bottom: 30px;
  padding: 21px 97px 20px 18px;
  overflow: hidden;
  color: #ff8576;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  background: linear-gradient(237.05deg, rgba(255, 255, 255, 0.5) -2.87%, rgba(2, 2, 2, 0.5) 98.76%), #fff3ed;
  background-blend-mode: soft-light, normal;
  border-radius: 14px;
}
.book__directory___1txGT .book__joinGroup___3Kya3 .book__close___zQfSw {
  position: absolute;
  top: 0;
  right: 0;
  width: 7px;
  height: 7px;
  padding: 9px;
}
.book__directory___1txGT .book__joinGroup___3Kya3 .book__joinBtn___23tpu {
  position: absolute;
  top: 0;
  right: 27px;
  bottom: 0;
  width: 64px;
  height: 28px;
  margin: auto;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background: #ff8576;
  border: none;
  border-radius: 8px;
}
.book__bookInfo___1uWCy {
  position: relative;
  min-height: 173px;
  margin-bottom: 50px;
  padding-left: 142px;
}
.book__bookInfo___1uWCy .book__bookCover___3l5NF {
  position: absolute;
  top: 0;
  left: 0;
  width: 122px;
  height: 173px;
  border-radius: 6px;
}
.book__bookInfo___1uWCy .book__bookTitle___3CeK3 {
  margin-bottom: 8px;
  padding-top: 3px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}
.book__bookInfo___1uWCy .book__explain___3kPFR {
  margin-bottom: 10px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.book__bookInfo___1uWCy .book__Introduction___3Y_mK {
  display: -webkit-box;
  overflow: hidden;
  color: #74706c;
  font-size: 12px;
  line-height: 18px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.book__chapter___1lZEM {
  margin-bottom: 50px;
}
.book__chapter___1lZEM .book__chapterHeader___AM76u {
  margin-bottom: 20px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
.book__chapter___1lZEM .book__chapterNum___3wpIi {
  margin-bottom: 12px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.book__chapter___1lZEM .book__chapterItem___3A-xZ {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 56px;
  padding: 0 0 0 10px;
  overflow: hidden;
  color: #484848;
  font-size: 14px;
  line-height: 56px;
}
.book__chapter___1lZEM .book__chapterItem___3A-xZ .book__chapterLabel___3_NTj {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book__chapter___1lZEM .book__chapterItem___3A-xZ::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #efedec;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  content: "";
}
.book__chapter___1lZEM .book__chapterItem___3A-xZ.book__active___1BXAd {
  color: #2e2e2e;
  font-weight: 600;
}
.book__comments___1sZAt .book__commentsNum___2OIMo {
  margin-bottom: 30px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
.book__comments___1sZAt .book__commentsHandle___3JmEg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 4px;
}
.book__comments___1sZAt .book__commentsHandle___3JmEg .book__commentsAvatar___1bqkn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.book__comments___1sZAt .book__commentsHandle___3JmEg .book__commentsBtn___4hK1F {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 285px;
  height: 38px;
  padding: 0 22px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  background: #f5f4f4;
  border: none;
  border-radius: 19px;
}
.book__qrcode___3zfFi {
  display: block;
  width: 130px;
  height: 130px;
  margin: 20px auto 24px;
}
.book__codeMsg___2lQNQ {
  margin-bottom: 9px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.book__playingIcon___3S9fS {
  width: 20px;
  height: 20px;
}
.book__nullComment___1IYIU {
  padding-top: 60px;
  color: #ccc6c0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.book__inputBox___3K0Pk {
  margin: 8px -12px;
}
.book__inputBox___3K0Pk textarea {
  width: 100%;
  height: 120px;
  color: #484848;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  border: none;
  resize: none;
}
.book__inputBox___3K0Pk textarea::-webkit-input-placeholder {
  color: #ccc6c0;
  font-weight: 600;
  opacity: 1;
}
.book__inputBox___3K0Pk textarea::-moz-placeholder {
  color: #ccc6c0;
  font-weight: 600;
  opacity: 1;
}
.book__inputBox___3K0Pk textarea:-ms-input-placeholder {
  color: #ccc6c0;
  font-weight: 600;
  opacity: 1;
}
.book__inputBox___3K0Pk textarea::-ms-input-placeholder {
  color: #ccc6c0;
  font-weight: 600;
  opacity: 1;
}
.book__inputBox___3K0Pk textarea::placeholder {
  color: #ccc6c0;
  font-weight: 600;
  opacity: 1;
}
.book__inputBox___3K0Pk textarea:-ms-input-placeholder {
  color: #ccc6c0;
  font-weight: 600;
}
.book__inputBox___3K0Pk textarea::-ms-input-placeholder {
  color: #ccc6c0;
  font-weight: 600;
}
.book__inputFns___3bluL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: -21px;
  font-weight: 500;
}
.book__inputFns___3bluL .book__textLen___j7SiU {
  color: #ccc6c0;
  font-size: 12px;
  line-height: 14px;
}
.book__inputFns___3bluL button {
  width: 70px;
  height: 34px;
  margin-right: -12px;
  margin-left: 18px;
  font-weight: 600;
}

/**
 * 移动端通用变量
 */
.Banner__banner___2sVPF {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}
.Banner__banner___2sVPF .Banner__tip___106Kr {
  padding: 12px 24px 13px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  background: rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.Banner__banner___2sVPF .Banner__tip___106Kr .Banner__timer___1Vdqp {
  margin: 0 4px;
  color: #f577ea;
}
.Banner__banner___2sVPF .Banner__tip___106Kr .Banner__award___1OuWv {
  color: #fff;
  font-weight: 500;
}
.Banner__banner___2sVPF .Banner__tip___106Kr .Banner__value___2uYpo {
  margin-left: 8px;
  color: #f577ea;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.Banner__banner___2sVPF .Banner__tip___106Kr .Banner__btn___Jbv6P {
  position: relative;
  top: -2px;
  float: right;
  width: 52px;
  height: 21px;
  color: #634cca;
  font-weight: 500;
  line-height: 14px;
  background: #fff;
  border-radius: 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Banner__banner___2sVPF .Banner__tip___106Kr .Banner__btn___Jbv6P::before {
  display: block;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  -webkit-transform: scale(0.833);
          transform: scale(0.833);
  content: "\67E5\770B\6D25\8D34";
}

/**
 * 通用颜色
 */
.Group__contentMain___3ymVR .Group__mainContTitle___nY-gt {
  padding: 110px 0 34px;
}
.Group__contentMain___3ymVR .Group__caseBox___4n_5I {
  width: 100%;
}
.Group__caseItem___2ESSs {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 333px;
  height: 198px;
  margin-top: 16px;
  padding: 0 26px;
  overflow: hidden;
  border: 1px solid #efedec;
  border-radius: 6px;
}
.Group__caseItem___2ESSs .Group__infoBox___9UFRQ {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 120px;
  padding: 28px 0 0 80px;
  overflow: hidden;
  border-bottom: 1px solid #efedec;
}
.Group__caseItem___2ESSs .Group__infoBox___9UFRQ .Group__avatarBox___u1Glu {
  position: absolute;
  top: 30px;
  left: 2px;
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.Group__caseItem___2ESSs .Group__infoBox___9UFRQ .Group__csaeTitle___6uj_W {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Group__caseItem___2ESSs .Group__infoBox___9UFRQ .Group__csaeDesc___3owTn {
  position: absolute;
  bottom: 28px;
  left: 80px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__userAvatar___2XQk6 {
  width: 24px;
  height: 24px;
  margin-left: -8px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__userAvatar___2XQk6:first-child {
  margin-left: -3px;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__userAvatarBtn___2yeuj {
  width: 28px;
  height: 28px;
  margin-left: -8px;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__btnBox___1TeRG {
  position: absolute;
  top: 22px;
  right: 0;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__joinGroupBtn___1Gz1H {
  position: relative;
  padding: 8px 12px 8px 35px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  background: #ff8576;
  border: none;
  border-radius: 6px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__joinGroupBtn___1Gz1H::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  width: 17px;
  height: 17px;
  margin: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAMAAAANf8AYAAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMAECAwQFBfYG9wf4CPkJ+gr7/P3+8OgMMtAAABQ0lEQVRIx+2W0XKDIBBFAWktGonAnv//1T4kJoJE6EymT7lv4B4WlsuOSn30DtlLFCRehm7CBDatpg/5Fp6S7y4EwFuttL0CdEBGIG3nMAmkvb0V0jPKJAgtZABMMbYNZgGfTVxhaTCxXNZCbDACOpvQIPvxxFQywPnMMaCdp8I0z1NhDnXzRd0qjAX2bjbFuMaocPDB+qjYXlPptw0a9n4j1z7R+PS1Bxi3D3OGzNnuxuz9jM0LvG1vfSDBqD5GqWGJgsSl4uiXzIn+i5mLit2t4HxIAkgKF2ebq+gxSHF3so6nxFQCNyX/svf8yD3CfRmtlNKDddd0m5tOOq7M5ZLGJYBYSWUSEOsnHhOZ2/eIO6lwBfIgZ2Ud5NBQbbNdHiN82QY6OqwUr76eKP3ZeWVMokd5HtfFFI6e25mS+/zQvEG/6wwot1iujCcAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  content: "";
}
.Group__caseItem___2ESSs .Group__caseBottom___35Xis .Group__joinGroupBtn___1Gz1H:hover {
  opacity: 0.7;
}

.institution__contentMain___1bKy- {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.institution__contentMain___1bKy- .institution__main___3sXeb {
  width: 650px;
  overflow: hidden;
}
.institution__contentMain___1bKy- .institution__fixedQrcode___1JB-5 {
  width: 262px;
}

/**
 * 通用颜色
 */
.Bargain__contentMain___1hGBo .Bargain__mainContTitle___akf0d {
  padding: 120px 0 50px;
}
.Bargain__contentMain___1hGBo .Bargain__caseBox___1rIbr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1048px;
  margin-bottom: -50px;
}
.Bargain__caseItem___3JHkK {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 193px;
  height: 323px;
  margin: 0 16px 50px 0;
  overflow: hidden;
  cursor: pointer;
}
.Bargain__caseItem___3JHkK .Bargain__headimg___1MbVh {
  width: 100%;
  height: 193px;
  margin-bottom: 16px;
  border-radius: 6px;
}
.Bargain__caseItem___3JHkK .Bargain__tagsBox___beHU- {
  height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
}
.Bargain__caseItem___3JHkK .Bargain__tag___26G_T {
  display: inline-block;
  margin: 0 5px;
  padding: 0 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  background: #ff8576;
  border-radius: 6px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.Bargain__caseItem___3JHkK .Bargain__caseTitle___25N4D {
  min-height: 44px;
  margin-bottom: 4px;
  padding: 0 4px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Bargain__caseItem___3JHkK .Bargain__casePrice___3Ey6w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3px;
  padding: 0 4px;
  color: #ff8576;
  font-weight: 600;
  font-size: 16px;
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 19px;
}
.Bargain__caseItem___3JHkK .Bargain__casePrice___3Ey6w .Bargain__unit___2zjTc {
  margin-right: 2px;
  padding-top: 4px;
  padding-bottom: 1px;
  font-size: 12px;
  font-family: "Arial", "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 14px;
}
.Bargain__caseItem___3JHkK .Bargain__casePrice___3Ey6w .Bargain__originalPrice___2z4bo {
  margin-left: 6px;
  color: #918d89;
  font-weight: 400;
  font-size: 13px;
  font-family: "SF Pro Text", "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
  line-height: 19px;
  text-decoration: line-through;
}
.Bargain__caseItem___3JHkK .Bargain__caseSort___2wSOY {
  padding: 0 4px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Bargain__caseItem___3JHkK:nth-child(even) {
  width: 194px;
}

.recruit-banner .slick-list {
  height: 223px;
  overflow: hidden;
  border-radius: 8px;
}

.recruit-banner .slick-prev,
.recruit-banner .slick-next {
  display: none !important;
}

.recruit-banner .slick-dots {
  bottom: -22px;
  height: 2px;
}
.recruit-banner .slick-dots li {
  width: 6px;
  height: 2px;
  margin: 0 4px;
  background: #ccc6c0;
  border-radius: 99px;
  opacity: 0.3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.recruit-banner .slick-dots li.slick-active {
  width: 6px;
  height: 2px;
  margin: 0 4px;
  background: #ccc6c0;
  opacity: 1;
}

.recruit-banner .slick-dots li button {
  opacity: 0 !important;
}
.recruit-banner .slick-dots li button::before {
  opacity: 0 !important;
}

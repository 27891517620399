/**
 * 通用颜色
 */
.offline__modal___3QwcP {
  position: relative;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.offline__modal___3QwcP .offline__mask___3Vxtz {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(46, 46, 46, 0.5);
  -ms-touch-action: none;
      touch-action: none;
}
.offline__modal___3QwcP .offline__container___3E7mK {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 300px;
  max-width: 600px;
  height: 409px;
  margin: auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 14px;
  -ms-touch-action: none;
      touch-action: none;
}
.offline__modal___3QwcP .offline__container___3E7mK .offline__qrcode___1jiiT {
  display: block;
  width: 168px;
  height: 168px;
  margin: 0 auto 30px;
}
.offline__modal___3QwcP .offline__main___256z2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 54px 28px 0;
}
.offline__modal___3QwcP .offline__main___256z2 .offline__close___2nnAc {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  width: 28px;
  height: 28px;
  padding: 18px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAMAAAArteDzAAAAolBMVEUAAADv7+//7+/////v7+/37+/39/f07+/09PTz8/Py8vLy8vL09PT29vb09PT29PT19fX39fXz8/P18/P29PTz8/P08/P19PT19fX08vL18/P19fX29fX18/P09PT29PT19PT19fX29PT19PT19fX29PT09PT18/P19PSwqaO0rai0rqjJxsHKxcHKxcLOysbOysfPycbSzsvSz8vTzsz19PQZRLcUAAAAKXRSTlMAEBAfICAgMDBAUGBgb3Bwf3+AgI+QkJ+foK+vr7C/v8/Pz9/f3+/v75a7XtQAAAKRSURBVFjDtZl9f9ogEMeJk85olq6sW2x1wTY2D2rj5nbv/60tNXVRA8dB6O9PNN/PPcABB2Mm3czEXGYZNMozuRTTCRumIF7m0FO+jMbuRAlaSSdumOSAK7HFhhIIssKOH4EoOvYuB7ruaGY+gZXWBGO/5GCp8tbEvAcH4SEIJDhJBv7C2WmlDex4Dc7SpSt4ggFaqSMgYZCkivkVBupeMT9hsHrzdZwPh5bXyVqDB608B7TVtwvn/TAvA5B4gsLSv6GNQv+Gni0Bj4Z2piY+ockHGAplW1gi7R/+bDZ7m/Gj4iM01f28rxq99sd3b+MHNFV67zeVknpkVlvtZwHqfQu9prZMBPrmv/40cqgU1Hdm9Uv72c8GWuozWfepJ2aN5J+xz9j86FEJTIBPbAoWVBITIiaATqUxQTDTqfGMSmTCXD/1e1Qqs5n+GVCpVCassRmlpJqZDZRSeWorJgAJ2lFJTCL0lCNVzXKF7jr3SVRC9s+ZJGpJgO4up9QrIfsplVnXVKpkD1SmshJqCqogM8lUwWZ0JpU6ZdyCSaRO0O1EUZcI1BLd+PaqtXmiHtCNLzZt0bWyDmyR3aS5kxmgtbq66KEcPfYcNHWpRvf99oSq9//3dvvXZvy/943/JfgUbw+oDz6Zyalf9AGGMnOlsjfUq6m8u0gt/BvKGC/9G8pMVdXpwsvYygfz5eq+7yMABb9uTcTDoXG/ifLdc0C9LIFU3ewalKznkbqFxl8GJJ7rmn3c2dZnjrQ6HeOajtAOqtPS+mFq9cbWq6CIzU1p23Rh4XQMQSGojX5O3rUeucWbBA2bhpavJ0ZskYQO7zw8QmZtGo9cn6R4tCgUNi7cie+aTMUizY7sLEvnYnZj/OQfga9hUy57nzcAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 28px;
}
.offline__modal___3QwcP .offline__main___256z2 .offline__title___1AM4c {
  margin-bottom: 26px;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.offline__modal___3QwcP .offline__main___256z2 .offline__desc___B9WGe {
  position: relative;
  margin-right: -0.35em;
  color: #484848;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  letter-spacing: 0.35em;
  text-align: center;
}
.offline__modal___3QwcP .offline__main___256z2 .offline__desc___B9WGe::after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: calc(100% - 0.35em);
  height: 12px;
  background-color: #ffecea;
  border-radius: 6px;
  content: "";
}
.offline__modal___3QwcP .offline__show___CQ8Qm {
  z-index: 999;
}
.offline__show___CQ8Qm {
  z-index: 999;
  opacity: 1;
}

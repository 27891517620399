/**
 * 通用颜色
 */
.LayerImage__layer___-7BZW {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.LayerImage__layer___-7BZW .LayerImage__clear___1hTyu {
  background: none;
  border: none;
  outline: none;
}
.LayerImage__layer___-7BZW .LayerImage__close___1nRLj {
  position: fixed;
  top: 80px;
  right: 80px;
  width: 38px;
  height: 38px;
  padding: 20px;
  cursor: pointer;
}
.LayerImage__main___4Lbru {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 860px;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 6px;
  -webkit-box-shadow: 0 30px 60px rgba(72, 72, 72, 0.1);
          box-shadow: 0 30px 60px rgba(72, 72, 72, 0.1);
}
.LayerImage__main___4Lbru .LayerImage__img___11mZk {
  width: 100%;
  height: auto;
}
.LayerImage__show___1Prnt {
  z-index: 999;
  opacity: 1;
}

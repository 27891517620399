/**
 * 通用颜色
 */
.Card__contentMain___3g-oQ {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 65px;
}
.Card__contentMain___3g-oQ .Card__title___3VgwY {
  margin-bottom: 40px;
  color: #484848;
  font-weight: 500;
  font-size: 46px;
  line-height: 130%;
}
.Card__contentMain___3g-oQ .Card__scoreSummary___Dzj5R {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 34px;
}
.Card__contentMain___3g-oQ .Card__scoreSummary___Dzj5R .Card__score___2L6s_ {
  width: 86px;
  height: 86px;
  margin-right: 20px;
  color: #ffffff;
  font-size: 36px;
  line-height: 86px;
  text-align: center;
  background: #ff8576;
  border-radius: 20px;
  -webkit-box-shadow: 0 10px 10px rgba(255, 133, 118, 0.15);
          box-shadow: 0 10px 10px rgba(255, 133, 118, 0.15);
}
.Card__scoreDetail___2SZ5v .Card__title___3VgwY {
  margin-bottom: 7px;
  color: #484848;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}
.Card__scoreDetail___2SZ5v .Card__scoreInfo___3t8vm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.Card__scoreDetail___2SZ5v .Card__scoreInfo___3t8vm .Card__scoreInfoItem___1XnYb {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 12px;
  padding-right: 12px;
  color: #635f5d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.Card__scoreDetail___2SZ5v .Card__scoreInfo___3t8vm .Card__scoreInfoItem___1XnYb .Card__scoreNum___mU-ez {
  padding-bottom: 1px;
  padding-left: 6px;
  color: #ff8576;
  font-size: 16px;
  line-height: 19px;
}
.Card__scoreDetail___2SZ5v .Card__scoreInfo___3t8vm .Card__scoreInfoItem___1XnYb::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 14px;
  margin: auto;
  background: #efedec;
  content: "";
}
.Card__scoreDetail___2SZ5v .Card__scoreInfo___3t8vm .Card__scoreInfoItem___1XnYb:last-child::after {
  display: none;
}
.Card__scoreBox___1jzSu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 {
  width: 307px;
  margin-bottom: 16px;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6:nth-child(2n) {
  width: 297px;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6:nth-child(2n) .Card__progress___m9UC3 {
  width: 260px;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 .Card__scoreTitle___3xag_ {
  margin-bottom: 4px;
  color: #635f5d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 .Card__scoreShow___1K1Tm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 .Card__progress___m9UC3 {
  position: relative;
  width: 270px;
  height: 6px;
  margin-top: 3px;
  overflow: hidden;
  background: #efedec;
  border-radius: 99px;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 .Card__progress___m9UC3 span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  margin: auto;
  background: #ff8576;
  border-radius: 99px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.Card__scoreBox___1jzSu .Card__scoreItem___20qs6 .Card__scoreNum___mU-ez {
  color: #ff8576;
  font-size: 15px;
  line-height: 19px;
}
.Card__noContent___YFQ_N {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 60px 0 55px;
}
.Card__noContent___YFQ_N .Card__icon___3rPB4 {
  width: 86px;
  height: 86px;
  margin-bottom: 40px;
}
.Card__noContent___YFQ_N .Card__msg___2oV9U {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}

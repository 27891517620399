/**
 * 移动端通用变量
 */
.Card__knowledge_wrapper___5fte0 {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}
.Card__knowledge_wrapper___5fte0 li {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: calc(100% - 40px);
  margin: 20px 0;
  overflow-y: auto;
  background: #fff;
}
.Card__knowledge_wrapper___5fte0 li.Card__content_more___2RC_z {
  height: calc(100% - 54px);
  margin-bottom: 34px;
}
.Card__image_wrapper___1tqUI {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-height: calc((100vw - 72px) * 0.561);
  max-height: calc((100vw - 72px) * 0.67);
  margin: 0 20px 0;
}
@media screen and (min-width: 600px) {
  .Card__image_wrapper___1tqUI {
    min-height: calc(528px * 0.561);
    max-height: calc(528px * 0.67);
  }
}
@media (max-width: 320px) {
  .Card__image_wrapper___1tqUI {
    min-height: calc((320px - 72px) * 0.561);
    max-height: calc((320px - 72px) * 0.67);
  }
}
.Card__image_wrapper___1tqUI img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Card__content_wrapper___3zoWV {
  margin: 16px 20px 0;
  color: #484848;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
  word-break: break-all;
}
.Card__goto___GpORW {
  padding-top: 8px;
  color: #ff6f61;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.Card__goto___GpORW::after {
  display: inline-block;
  width: 5px;
  height: 10px;
  margin-left: 4px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAeCAYAAADzXER0AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE/SURBVHgBlZXvbcJADMXtQ/3eEegGZYRu0G4Q8rFSSzpByQQQMQC3AWzACDBCRsgEGJ9B6C73N09KFNn5+fmdggB4iL6rd3PBBCkBV/UGXvBsLnkuFFLDbsSgrSv94U5vc7AKV3EjQ3MwbvWFFzh6HcIDD3hNwnLH65Lv/ag3B1L7LMzuA6f/8tv0ST9VE4PRebXhFwn900Za3OMlYBmwqg/GcVTuHwMGGK/tjivP78FT8iNEVJI/CsuATH6VgnP5k87iHvr2haSFgryGWENlXHlFPAU62hxa2pnUP5iMrvjAoE0602/NIFVeA+mDXXvzOIPYugDHANgy+KxjELznnI86Gju9tCv+2pmcUbgkp62Zs25BTqf1BAtz+mtPyOkuFf9230I5fWcfbHOgwPLDJuossOPaGqbIrF/yL2HrBiLhp/w0QVWNAAAAAElFTkSuQmCC");
  background-size: cover;
  content: "";
}

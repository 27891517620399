/**
 * 通用颜色
 */
.Category__categoryNavCont___3AjD9 {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 217px;
  min-height: 319px;
  padding: 26px 0 21px 30px;
  background: #ff6f61;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
}
.Category__categoryNavCont___3AjD9 .Category__categoryNavTitle___IQ6Vb {
  margin-bottom: 16px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  opacity: 0.75;
}
.Category__categoryItemBox___30pXK {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 490px;
}
.Category__categoryItemBox___30pXK:hover .Category__categoryItem___1pd4l {
  color: rgba(255, 255, 255, 0.5);
}
.Category__categoryItemBox___30pXK:hover .Category__categoryItem___1pd4l::after {
  opacity: 0.5;
}
.Category__categoryItemBox___30pXK .Category__active___1P3N3 .Category__categoryItem___1pd4l {
  color: #ffffff;
  font-weight: 600;
}
.Category__categoryItemBox___30pXK .Category__active___1P3N3 .Category__categoryItem___1pd4l::after {
  opacity: 1;
}
.Category__categoryItemBox___30pXK .Category__active___1P3N3 .Category__categoryDetail___illAA {
  display: block;
}
.Category__categoryView___1qPxb {
  display: inline-block;
  height: 26px;
}
.Category__categoryView___1qPxb .Category__categoryItem___1pd4l {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 19px;
  padding-bottom: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-decoration: none;
  outline: none;
}
.Category__categoryView___1qPxb .Category__categoryItem___1pd4l::after {
  position: absolute;
  top: 4px;
  right: 0;
  width: 13px;
  height: 13px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAARUlEQVR4Ae3TwQkAIAxD0eAk7r9UR4kIvXtqiTQfvEkfFAq47yMZ+TYqS4Tl2B1uzJgm9vq/oFzL6owMRRJqO8woR5xEB0xXGVeudzr6AAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  content: "";
}
.Category__categoryView___1qPxb .Category__categoryDetail___illAA {
  position: absolute;
  top: -2px;
  left: 207px;
  display: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 155px;
  height: 100%;
  min-height: 319px;
  padding: 62px 20px 20px 30px;
  background: #ff6f61;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.Category__categoryView___1qPxb .Category__categoryDetail___illAA .Category__categoryDetailItem___38tRO {
  display: block;
  color: #ffffff;
  font-size: 13px;
  line-height: 22px;
  text-decoration: none;
  outline: none;
}
.Category__categoryView___1qPxb .Category__categoryDetail___illAA .Category__categoryDetailItem___38tRO:active,
.Category__categoryView___1qPxb .Category__categoryDetail___illAA .Category__categoryDetailItem___38tRO:hover {
  font-weight: 600;
}

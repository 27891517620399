/**
 * 移动端通用变量
 */
.User__user_wrapper___zwtEM {
  position: relative;
  max-height: 172px;
  overflow: hidden;
}
.User__user_wrapper___zwtEM li {
  position: relative;
  height: 40px;
  margin-bottom: 12px;
  padding: 20px 20px 20px 76px;
  font-weight: 500;
  background: #f9f9f9;
  border-radius: 8px;
}
.User__user_wrapper___zwtEM li img {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.User__user_wrapper___zwtEM li .User__name___1cnfV {
  color: #484848;
  font-size: 14px;
  line-height: 20px;
}
.User__user_wrapper___zwtEM li .User__learning___2XcE6 {
  margin-top: 3px;
  color: #b0a9a3;
  font-size: 12px;
  line-height: 17px;
}
.User__user_wrapper___zwtEM li .User__learning___2XcE6 span {
  margin: 0 4px;
  color: #ff8576;
}

/**
 * 移动端通用变量
 */
.Banner__banner___WM7ln {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 20px 0 16px;
}
.Banner__banner___WM7ln .Banner__tip___wX4GU {
  padding: 13px 24px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
}
.Banner__banner___WM7ln .Banner__tip___wX4GU .Banner__timer___1pFyJ {
  margin: 0 4px;
  color: #f9fd50;
}
.Banner__banner___WM7ln .Banner__tip___wX4GU .Banner__award___2Fjxn {
  color: #fff;
  font-weight: 500;
}
.Banner__banner___WM7ln .Banner__tip___wX4GU .Banner__value___8QlI_ {
  margin-left: 8px;
  color: #f9fd50;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.Banner__banner___WM7ln .Banner__tip___wX4GU .Banner__btn___hCEEO {
  position: relative;
  top: -2px;
  float: right;
  width: 52px;
  height: 21px;
  color: #ff6f61;
  font-weight: 500;
  line-height: 14px;
  background: #fff;
  border-radius: 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Banner__banner___WM7ln .Banner__tip___wX4GU .Banner__btn___hCEEO::before {
  display: block;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  -webkit-transform: scale(0.833);
          transform: scale(0.833);
  content: "\67E5\770B\6D25\8D34";
}

/**
 * 通用颜色
 */
.Order__content___17HVg {
  margin-bottom: 100px;
}
.Order__content___17HVg .Order__title___jWLgd {
  margin-bottom: 52px;
  color: #484848;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Order__box___3Z-Qy {
  position: relative;
  width: 650px;
  margin-bottom: 56px;
}
.Order__box___3Z-Qy .Order__input___2gBml {
  position: relative;
  z-index: 222;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 0 4px 10px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  background: none;
  border: none;
  border-bottom: 1px solid #efedec;
}
.Order__box___3Z-Qy .Order__input___2gBml:focus {
  border-bottom: 1px solid #ff8576;
}
.Order__box___3Z-Qy .Order__label___YRiJA {
  position: absolute;
  top: 2px;
  left: 4px;
  z-index: 111;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.Order__focus___348vw .Order__label___YRiJA {
  top: -19px;
  color: #484848;
  font-size: 12px;
  line-height: 17px;
}
.Order__last___jRZKP {
  margin-bottom: 80px;
}
.Order__btn___1IvaN {
  width: 186px;
  height: 44px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  background: #ff8576;
  border: none;
  border-radius: 4px;
}

/**
 * 通用颜色
 */
.Sort__content___2MaVv {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 12px;
  padding-left: 54px;
  border-bottom: 1px solid #efedec;
}
.Sort__content___2MaVv::after {
  position: absolute;
  top: 2px;
  left: 2px;
  color: #ccc6c0;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  content: "\5206\7C7B";
}
.Sort__content___2MaVv .Sort__item___14QE1 {
  margin: 0 30px 8px 0;
  color: #74706c;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background: none;
  border: none;
}
.Sort__content___2MaVv .Sort__item___14QE1:hover {
  color: #ff8576;
  cursor: pointer;
}
.Sort__content___2MaVv .Sort__active___2KCNX {
  color: #ff8576;
}

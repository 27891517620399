/**
 * 通用颜色
 */
.SkuList__content___2unuw {
  margin-bottom: 122px;
}
.SkuList__content___2unuw .SkuList__list___1cQsx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 105%;
}
.SkuList__content___2unuw .SkuList__noContent___3B4L0 {
  padding: 150px 0;
  color: #ccc6c0;
  text-align: center;
}
.SkuList__item___2l9BR {
  width: 333px;
  margin-right: 16px;
  margin-bottom: 60px;
  cursor: pointer;
}
.SkuList__item___2l9BR .SkuList__avatar___32r9S {
  width: 100%;
  height: 222px;
  margin-bottom: 14px;
  border-radius: 6px;
}
.SkuList__item___2l9BR .SkuList__title___1Smqo {
  min-height: 50px;
  margin-bottom: 8px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.SkuList__item___2l9BR .SkuList__price___1XT5w {
  color: #ff8576;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.SkuList__item___2l9BR:hover .SkuList__title___1Smqo {
  color: #ff8576;
}

/**
 * 通用颜色
 */
.about__main___3J0j2 {
  padding: 20px;
}
.about__main___3J0j2 .about__title___35OOK {
  margin-bottom: 40px;
  color: #484848;
  font-weight: 600;
  font-size: 38px;
  line-height: 53px;
}
.about__item___7QcYU {
  padding-bottom: 30px;
}
.about__item___7QcYU .about__title___35OOK {
  margin-bottom: 20px;
  color: #484848;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.about__item___7QcYU .about__msg___1teRj {
  margin-bottom: 30px;
  color: #635f5d;
  font-size: 16px;
  line-height: 26px;
}
.about__item___7QcYU .about__secTitle___2WIhp {
  margin-bottom: 20px;
  color: #484848;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

/**
 * 通用颜色
 */
.Tabs__contentMain___25V0u {
  margin-bottom: 100px;
}
.Tabs__contentMain___25V0u .Tabs__tabs____0G21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 80px;
  border-bottom: 1px solid #efedec;
}
.Tabs__contentMain___25V0u .Tabs__tabs____0G21 .Tabs__tab___1DpNe {
  position: relative;
  margin-right: 38px;
  padding-bottom: 13px;
  color: #b0a9a3;
  font-size: 22px;
  line-height: 31px;
  white-space: nowrap;
  cursor: pointer;
}
.Tabs__contentMain___25V0u .Tabs__tabs____0G21 .Tabs__active___2JTGk {
  color: #ff8576;
}
.Tabs__contentMain___25V0u .Tabs__tabs____0G21 .Tabs__active___2JTGk::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ff8576;
  border-radius: 5px;
  content: "";
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 {
  position: relative;
  margin-bottom: 80px;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__title___dsra5 {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__msg____gBzA {
  font-size: 16px;
  line-height: 26px;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__shopsTitle___2Rh-f {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__shop___27cZ3 {
  margin-bottom: 16px;
  color: #b0a9a3;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__shop___27cZ3 span {
  color: #484848;
}
.Tabs__contentMain___25V0u .Tabs__item___-MlD8 .Tabs__shop___27cZ3:last-child {
  margin-bottom: 0;
}
.Tabs__contentMain___25V0u .Tabs__user___1T0T0 {
  position: relative;
  min-height: 48px;
  padding: 24px 0 24px 60px;
  border-bottom: 1px solid #efedec;
}
.Tabs__contentMain___25V0u .Tabs__user___1T0T0 .Tabs__avatar___3rKuj {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 48px;
  height: 48px;
  margin: auto;
  border-radius: 50%;
}
.Tabs__contentMain___25V0u .Tabs__user___1T0T0 .Tabs__name___8CjSS {
  margin-bottom: 5px;
  padding-top: 1px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Tabs__contentMain___25V0u .Tabs__user___1T0T0 .Tabs__time___2622f {
  color: #74706c;
  font-size: 14px;
  line-height: 20px;
}
.Tabs__contentMain___25V0u .Tabs__btns___25hcR {
  position: absolute;
  top: 9px;
  right: 0;
  width: 52px;
}
.Tabs__contentMain___25V0u .Tabs__btns___25hcR .Tabs__ordersBtn___3570u {
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  border: none;
}

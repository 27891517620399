/**
 * 通用颜色
 */
.Advantage__contentMain___KMveF {
  padding-bottom: 79px;
}
.Advantage__contentMain___KMveF .Advantage__mainContTitle___1QPuJ {
  padding: 110px 0 40px;
}
.Advantage__caseBox___1FjiP {
  width: 100%;
}
.Advantage__serverItem___2Poix {
  position: relative;
  width: 208px;
}
.Advantage__serverItem___2Poix .Advantage__iconBox___2doF9 {
  position: relative;
  width: 208px;
  height: 120px;
  margin-top: 36px;
  margin-bottom: 22px;
  background: #f5f4f4;
  border-radius: 6px;
}
.Advantage__serverItem___2Poix .Advantage__iconBox___2doF9 .Advantage__caseIcon___21mjQ {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 208px;
  height: 156px;
}
.Advantage__serverItem___2Poix::after {
  position: absolute;
  top: 169px;
  right: -40px;
  width: 27px;
  height: 25px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABLBAMAAAD67Hb4AAAALVBMVEUAAADj4d3l49/l49/m49/k5N/l49/m49/k4d/k4d3i39/j49/n39/f39/l49/OVvcIAAAADnRSTlMAgKDvjzDPv3BwUEAgEEtpCx8AAAB8SURBVEjHY8AGhE0YiAMs794pEKeS+d27R8SqJNZQxndEG6pHtKFMo4aOGjpq6KihA2go70RBAkAYamjwO8IAYmgecSpfMzC8Iw48ZGCoI07lK1LcydNIlN+pG0lMo0aOGjlq5KiRA24kC8hIMtvJlLe9GWFGEgbOhlgEAX7Mnly2ZHsJAAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  content: "";
}
.Advantage__serverItem___2Poix:last-child::after {
  display: none;
}
.Advantage__serverItem___2Poix .Advantage__caseTitle___2x8l4 {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Advantage__serverItem___2Poix .Advantage__caseMsg___1GSHG {
  color: #635f5d;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-line;
}
.Advantage__caseItem___2zzZs {
  width: 298px;
  text-align: center;
}
.Advantage__caseItem___2zzZs .Advantage__caseIcon___21mjQ {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-bottom: 12px;
}
.Advantage__caseItem___2zzZs .Advantage__caseTitle___2x8l4 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.Advantage__caseItem___2zzZs .Advantage__caseMsg___1GSHG {
  color: #635f5d;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-line;
}

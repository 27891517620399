/**
 * 通用颜色
 */
.contact__main___nq2k0 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 62px 0 170px;
}
.contact__main___nq2k0 .contact__sticky___1VD6d {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 100%;
}
.contact__main___nq2k0 .contact__left___Tax_f {
  position: absolute;
  top: -2px;
  left: 0;
  width: 360px;
}
.contact__main___nq2k0 .contact__left___Tax_f .contact__pageTitle___2QzwC {
  color: #484848;
  font-weight: 600;
  font-size: 38px;
  line-height: 53px;
}
.contact__box___1rEK_ {
  width: 650px;
  margin-bottom: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #efedec;
}
.contact__box___1rEK_ .contact__msg___3mrRD {
  color: #484848;
  font-size: 18px;
  line-height: 29px;
}
.contact__cont___3BVzP {
  width: 650px;
}
.contact__cont___3BVzP dl {
  margin: 0;
}
.contact__cont___3BVzP dt,
.contact__cont___3BVzP dd {
  display: inline;
  padding: 0;
  color: #484848;
  font-size: 16px;
  line-height: 26px;
}
.contact__cont___3BVzP .contact__br___3LbGJ {
  margin: 10px 0;
  border: none;
}
.contact__title___1KNdi {
  margin-bottom: 20px;
  color: #484848;
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
}

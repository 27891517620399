/**
 * 通用颜色
 */
.Shop__content___285mY {
  position: relative;
}
.Shop__content___285mY .Shop__list___D5Ui4 {
  margin-bottom: 21px;
}
.Shop__content___285mY .Shop__item___2L6wP {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 201px;
  padding: 40px 0 40px 142px;
  overflow: hidden;
  border-bottom: 1px solid #efedec;
}
.Shop__content___285mY .Shop__item___2L6wP:last-child {
  border-bottom: none;
}
.Shop__content___285mY .Shop__item___2L6wP .Shop__avatar___WRzqI {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 6px;
  cursor: pointer;
}
.Shop__content___285mY .Shop__main___2-m85 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 120px;
}
.Shop__content___285mY .Shop__main___2-m85 .Shop__title___3wzXH {
  width: 295px;
  margin-bottom: 4px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}
.Shop__content___285mY .Shop__main___2-m85 .Shop__desc___2CXXF {
  margin-bottom: 8px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Shop__content___285mY .Shop__main___2-m85 .Shop__mark___3EAtr {
  width: 49px;
  height: 18px;
}
.Shop__bottom___2JO-C {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.Shop__bottom___2JO-C .Shop__score___2Ag7v {
  margin-right: 3px;
  color: #ff8576;
  font-size: 18px;
  line-height: 22px;
}
.Shop__bottom___2JO-C .Shop__scoreUnit___3ZK9B {
  margin-right: 12px;
  padding: 4px 0 1px;
  color: #ff8576;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.Shop__bottom___2JO-C .Shop__soldNum___xuTNc {
  padding: 4px 0 1px;
  color: #ccc6c0;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.Shop__noContent___2taXl {
  padding: 150px 0;
  color: #ccc6c0;
  text-align: center;
}

/**
 * 通用颜色
 */
.Banner__content___1chSV {
  position: relative;
  width: 100%;
  min-width: 1032px;
  height: 672px;
  overflow: hidden;
  background-color: #ff6f61;
  background-image: url(/_next/static/images/banner_bg-9ea54845b62c8aadec02a36789cc808d.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.Banner__content___1chSV::after {
  position: absolute;
  bottom: 0;
  left: calc(50% + 211px);
  width: 584px;
  height: 584px;
  margin: auto;
  background: url(/_next/static/images/banner_character-3738754da83271a1b5bdfdc7981d86a7.png);
  background-size: 100% 100%;
  content: "";
}
.Banner__contentMain___2aRS6 {
  position: relative;
  width: 1032px;
  margin: 0 auto;
  color: #484848;
}
.Banner__contentMain___2aRS6 .Banner__categoryContainer___3aOyg {
  position: absolute;
  top: 167px;
  left: 0;
  z-index: 222;
}
.Banner__contentMain___2aRS6 .Banner__title___3CCNw {
  position: absolute;
  top: 185px;
  left: 327px;
  z-index: 220;
  width: 504px;
  height: 296px;
}
.Banner__contentMain___2aRS6 .Banner__qrcode___2ok55 {
  position: absolute;
  top: 390px;
  left: 336px;
  z-index: 221;
  width: 82px;
  height: 82px;
}
.Banner__caseBox___2-Yo8 {
  position: relative;
  padding: 46px 0 0 264px;
}
.Banner__caseBox___2-Yo8 .Banner__caseItem___3Dlct {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 768px !important;
  height: 437px;
  overflow: hidden;
}
.Banner__caseBox___2-Yo8 .Banner__caseItem___3Dlct .Banner__headimg___3of62 {
  width: 768px;
  height: 437px;
  border-radius: 6px;
}

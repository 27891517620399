.tuan__contentMain___3vqhR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.tuan__contentMain___3vqhR .tuan__main___3DZBT {
  width: 650px;
  overflow: hidden;
}
.tuan__contentMain___3vqhR .tuan__fixedQrcode___1bKqC {
  width: 262px;
}

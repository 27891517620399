/**
 * 通用颜色
 */
.BookComment__commentWrap___1veGP {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.BookComment__avatar___3e6-0 {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.BookComment__right___19yRq {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 12px;
  padding-bottom: 22px;
}
.BookComment__right___19yRq::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #efedec;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  content: "";
}
.BookComment__userInfo___1IHe5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.BookComment__userName___vqp82 {
  max-width: 120px;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.BookComment__commentDate___GIjyC {
  margin-top: 2px;
  color: #b0a9a3;
  font-size: 12px;
  line-height: 17px;
}
.BookComment__content___2EBI8 {
  margin-top: 8px;
  color: #635f5d;
  font-size: 14px;
  line-height: 22px;
  word-break: break-all;
}

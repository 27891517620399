/**
 * 移动端通用变量
 */
.share__content___aUThj {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 100vh;
  padding: 30px 30px 89px;
  overflow: hidden;
  background-color: #fff;
}
.share__content___aUThj .share__comments___3I5WL {
  margin-bottom: 20px;
  color: #222;
  font-size: 22px;
  line-height: 36px;
}
.share__content___aUThj .share__user___1MC5s {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 26px;
  padding-top: 51px;
  overflow: hidden;
}
.share__content___aUThj .share__user___1MC5s .share__quotes___2MlaY {
  position: absolute;
  top: 0;
  right: 16px;
  width: 68px;
  height: 45px;
}
.share__content___aUThj .share__user___1MC5s .share__avatar___39f1O {
  display: block;
  width: 80px;
  height: 80px;
  margin-bottom: 18px;
  border-radius: 50%;
}
.share__content___aUThj .share__user___1MC5s .share__name___2fV8r {
  width: 60%;
  color: #222;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.share__content___aUThj .share__description___1dwBg {
  margin-bottom: 46px;
  padding: 0 11px;
  color: #222;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}
.share__content___aUThj .share__description___1dwBg .share__highlight___2yP9R {
  position: relative;
  font-weight: 600;
}
.share__content___aUThj .share__description___1dwBg .share__highlight___2yP9R::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 9px;
  background: #fee5e2;
  border-radius: 99px;
  content: "";
}
.share__content___aUThj .share__description___1dwBg .share__highlight___2yP9R span {
  position: relative;
  z-index: 1;
}
.share__content___aUThj .share__qrcodeBox___1_RsR {
  position: relative;
  width: 170px;
  height: 170px;
  margin: 0 auto 46px;
  overflow: hidden;
}
.share__content___aUThj .share__qrcodeBox___1_RsR .share__qrcode___SGTqR {
  width: 100%;
  height: 100%;
}
.share__content___aUThj .share__qrcodeBox___1_RsR .share__brandIcon___2XAL- {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 170px;
  height: 170px;
  margin: auto;
}
.share__content___aUThj .share__qrcodeMsg___26ccv {
  width: calc(100% - 70px);
  height: 49px;
  margin: 0 auto;
  color: #222;
  font-weight: 600;
  font-size: 18px;
  line-height: 49px;
  text-align: center;
  background: #f7f7f7;
  border-radius: 23px;
}
.share__content___aUThj .share__copyright___1KsBz {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  color: #ccc6c0;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}
.share__content___aUThj .share__bannerBox___1WBMJ {
  position: relative;
  width: 100%;
  margin-bottom: 42px;
  overflow: hidden;
}
.share__content___aUThj .share__bannerBox___1WBMJ .share__banner___30Lnm {
  height: 223px;
}
.share__content___aUThj .share__bannerBox___1WBMJ .share__videoBox___g9wm1 {
  width: 100%;
  background: #000;
}

/**
 * 通用颜色
 */
.ServiceScore__contentMain___kVQm- .ServiceScore__contentTitle___3AVQF {
  color: #484848;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.ServiceScore__contentMain___kVQm- .ServiceScore__evaluationList___3P9rF {
  margin-bottom: 60px;
}
.ServiceScore__evaluationItem___c0E87 {
  padding: 40px 0;
  border-bottom: 1px solid #efedec;
}
.ServiceScore__evaluationItem___c0E87:last-child {
  border-bottom: none;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__userInfo___3AhDJ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 24px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__userInfo___3AhDJ .ServiceScore__avatar___2ygZV {
  width: 48px;
  height: 48px;
  margin-right: 14px;
  background: #f5f4f4;
  border-radius: 50%;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__userInfo___3AhDJ .ServiceScore__name___3Nryn {
  margin-bottom: 6px;
  color: #74706c;
  font-size: 16px;
  line-height: 21px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__userInfo___3AhDJ .ServiceScore__name___3Nryn span {
  margin-right: 10px;
  color: #484848;
  font-weight: 500;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__userInfo___3AhDJ .ServiceScore__date___L1E3P {
  height: 17px;
  color: #74706c;
  font-size: 14px;
  line-height: 17px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__evaluationMsg___7Mtd7 {
  margin-bottom: 20px;
  color: #484848;
  font-size: 16px;
  line-height: 26px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__imgList___1qKzO {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 10px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__imgList___1qKzO .ServiceScore__imgItem___1cJUE {
  width: 98px;
  height: 98px;
  margin: 0 10px 10px 0;
  background: #f5f4f4;
  cursor: pointer;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 21px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL .ServiceScore__total___1OYEZ {
  margin-right: 10px;
  color: #ff8576;
  font-size: 26px;
  line-height: 34px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL .ServiceScore__right___1_2q9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 3px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL .ServiceScore__scoreName___24fLQ {
  margin-right: 6px;
  color: #635f5d;
  font-size: 14px;
  line-height: 20px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL .ServiceScore__scoreNum___aD1MB {
  padding-bottom: 1px;
  color: #ff8576;
  font-size: 16px;
  line-height: 19px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreBox___2LNzL .ServiceScore__scoreLine___1q3po {
  width: 1px;
  height: 14px;
  margin: 0 12px 3px;
  background: #efedec;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 220px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 {
  width: 195px;
  margin-bottom: 12px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 .ServiceScore__scoreTitle___2CN2S {
  color: #635f5d;
  font-size: 13px;
  line-height: 18px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 .ServiceScore__scoreShow___EvSj1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 .ServiceScore__progress___3qlzM {
  position: relative;
  width: 160px;
  height: 4px;
  margin-top: 5px;
  overflow: hidden;
  background: #efedec;
  border-radius: 99px;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 .ServiceScore__progress___3qlzM span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  margin: auto;
  background: #ff8576;
  border-radius: 99px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ServiceScore__evaluationItem___c0E87 .ServiceScore__scoreDetailBox___1QFSo .ServiceScore__scoreItem___1dM72 .ServiceScore__scoreNum___aD1MB {
  color: #ff8576;
  font-size: 15px;
  line-height: 19px;
}
.ServiceScore__noContent___38GJ_ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 100px 0 180px;
  color: #ccc6c0;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}
.ServiceScore__pagination___1L8-X {
  margin-bottom: 100px;
}

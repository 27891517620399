/**
 * 移动端通用变量
 */
.guide__content___3yWtS {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 100vh;
  padding: 0 16px 74px;
  overflow: hidden;
  background: #ff6f61;
}
.guide__content___3yWtS::before {
  position: absolute;
  top: -64px;
  right: 0;
  width: 375px;
  height: 477px;
  background: url(/_next/static/images/bg-1-76f0042314abe6bfa16d992e9b1b0ea2.png);
  background-size: 100% 100%;
  content: "";
}
.guide__content___3yWtS::after {
  position: absolute;
  top: 384px;
  right: 0;
  width: 375px;
  height: 893px;
  background: url(/_next/static/images/bg-2-c845dec7b234e0787c292fc93031aaca.png);
  background-size: 100% 100%;
  content: "";
}
.guide__content___3yWtS .guide__bgIcon___245C7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 193px;
}
.guide__content___3yWtS .guide__header___KHY9- {
  position: relative;
  z-index: 555;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 115px;
}
.guide__content___3yWtS .guide__header___KHY9- .guide__title___29Wiw {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.guide__content___3yWtS .guide__main___3VlQ5 {
  position: relative;
  z-index: 555;
  background: #fff;
  border-radius: 14px;
}
.guide__groupList___2HlQm {
  padding: 4px 20px;
}
.guide__groupList___2HlQm .guide__item___KXtcE {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 94px;
  padding: 22px 36px 22px 64px;
}
.guide__groupList___2HlQm .guide__item___KXtcE::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #f5f4f4;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  content: "";
}
.guide__groupList___2HlQm .guide__item___KXtcE:last-child::after {
  display: none;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__avatar___3MsYx {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 44px;
  margin: auto;
  padding: 3px;
  overflow: hidden;
  background-color: #efedec;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 2px;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__groupTitie___1KApB {
  padding: 4px 0;
  color: #484848;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__groupMsg___2p9A_ {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__groupTitie___1KApB,
.guide__groupList___2HlQm .guide__item___KXtcE .guide__groupMsg___2p9A_ {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__checkBox___1eL5R {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: auto;
  overflow: hidden;
  background: #fafafa;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}
.guide__groupList___2HlQm .guide__item___KXtcE .guide__checked___WtuEZ {
  background: #ff8576;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAUVBMVEUAAAD/hXb/hXX/hXX/gnP/hXb/hXf/g3X/hXX/gHD/h3j/hHb/hXX/g3b/h3b/hXb/////0cz/+Pf/nJD/m4//7er/ysP/2dX/wrr/q6H/lIdWvFczAAAAD3RSTlMA379wIO+fkGAQIKCfgH8CB1ylAAAA70lEQVRIx+3Xy67CMAxFUSfpk5dDL+0F/v9DEVWkg8QA+2QAQuz5UuRJZEtpTLsYgr4shNgPjTzWRHXVgUtq1VmbpHRQokN5V6nSOm/L4fY+d6dkUaRRulEGHifpedxJ5HGUwONQh7WiH/42vEzzicXHnPPsxLD3/Bh2cWPYoxIYlsCwNnw5X5+sFS85T6cna8Q5Fw1rx3PRsA78NxUNa8XQ/7BWDA3rwNCwJgwN68DQsFYMPU/LJ3xDP/wB+G07Sd0qteVxL4nHg4w8boQfuhORDb2sV50Ja3vG7itPo9Jm67NxnRd86K3n4C6NBd0ALyrLfTcTTagAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  border: none;
}
.guide__codesList___DXTpA {
  padding: 10px 20px;
}
.guide__codesList___DXTpA .guide__codeItem___1XR1J,
.guide__codesList___DXTpA .guide__scanCode___DYtpQ {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 70px 0;
}
.guide__codesList___DXTpA .guide__codeItem___1XR1J::after,
.guide__codesList___DXTpA .guide__scanCode___DYtpQ::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #f5f4f4;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  content: "";
}
.guide__codesList___DXTpA .guide__codeItem___1XR1J:last-child::after,
.guide__codesList___DXTpA .guide__scanCode___DYtpQ:last-child::after {
  display: none;
}
.guide__codesList___DXTpA .guide__codeItem___1XR1J .guide__qrcode___3uzwL,
.guide__codesList___DXTpA .guide__scanCode___DYtpQ .guide__qrcode___3uzwL {
  display: block;
  width: 180px;
  height: 180px;
  margin-bottom: 40px;
}
.guide__codesList___DXTpA .guide__codeItem___1XR1J .guide__qrcodeMsg___1ELuV,
.guide__codesList___DXTpA .guide__scanCode___DYtpQ .guide__qrcodeMsg___1ELuV {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 226px;
  padding: 10px 15px;
  color: #484848;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  background: #f7f7f7;
  border-radius: 99px;
}
.guide__codesList___DXTpA .guide__scanCode___DYtpQ {
  padding: 50px 0 70px;
}
.guide__codesList___DXTpA .guide__scanCode___DYtpQ .guide__scanCodeMsg___2mcbF {
  margin-bottom: 42px;
  color: #484848;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
.guide__codesList___DXTpA .guide__scanCode___DYtpQ .guide__scanCodeMsg___2mcbF em {
  padding: 0 5px;
}
.guide__fixedBtn___1wzoK {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 666;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 58px;
  background: #f65a5a;
}
.guide__fixedBtn___1wzoK .guide__btn___3FE4G {
  position: relative;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.guide__fixedBtn___1wzoK .guide__btn___3FE4G .guide__badge___3uX22 {
  position: absolute;
  top: -7px;
  right: -18px;
  padding: 2px 8px;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  background: #ff9960;
  border-radius: 99px;
}
.guide__scanSubMsg___2uO85 {
  padding-top: 15px;
  font-size: 16px;
}

/**
 * 移动端通用变量
 */
.address__main___1agTK {
  padding: 10px 16px;
}
.address__cell___2SetF {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #efedec;
}
.address__label___1vhgQ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 60px;
  margin-right: 30px;
  color: #484848;
}
.address__label___1vhgQ span {
  display: inline-block;
  min-width: 100%;
  font-weight: bold;
  text-align: justify;
  -moz-text-align-last: justify;
       text-align-last: justify;
}
.address__required___2WQWd {
  position: relative;
}
.address__required___2WQWd::after {
  content: "*";
}
.address__value___2pKWR {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
}
.address__value___2pKWR input {
  width: 100%;
  height: 74px;
  color: #484848;
  border: none;
}
.address__value___2pKWR input::-webkit-input-placeholder {
  color: #b0a9a3;
}
.address__value___2pKWR input::-moz-placeholder {
  color: #b0a9a3;
}
.address__value___2pKWR input:-ms-input-placeholder {
  color: #b0a9a3;
}
.address__value___2pKWR input::-ms-input-placeholder {
  color: #b0a9a3;
}
.address__value___2pKWR input::placeholder {
  color: #b0a9a3;
}
.address__footer___1ntDd {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: env(safe-area-inset-bottom);
  border-top: 1px solid #fee5e2;
}
.address__event___3V94Q {
  padding: 30px;
  text-align: center;
}
.address__title___2VHJD {
  margin-bottom: 50px;
  padding: 16px 0;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 16px;
  background-color: #f7f7f7;
  border-radius: 8px;
}
.address__success___2aUZJ {
  margin-bottom: 46px;
  padding-bottom: 40px;
  border-bottom: 1px dashed #efedec;
}
.address__success___2aUZJ img {
  width: 42px;
  height: 42px;
  margin-bottom: 12px;
  vertical-align: bottom;
}
.address__success___2aUZJ h2 {
  margin-bottom: 6px;
  color: #484848;
  font-weight: 600;
  font-size: 18px;
}
.address__success___2aUZJ p {
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
}
.address__code___2WjHb {
  padding-top: 28px;
  font-size: 13px;
  border: 1px solid #efedec;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 0 rgba(245, 244, 244, 0.5);
          box-shadow: 0 2px 0 rgba(245, 244, 244, 0.5);
}
.address__code___2WjHb em {
  display: inline-block;
  margin: 6px 0 21px;
  color: #484848;
  font-size: 30px;
  font-family: "SF Pro Text", sans-serif;
}
.address__tip___3j-RE {
  padding: 18px 0;
  font-size: 11px;
  border-top: 1px solid #efedec;
}

/**
 * 通用颜色
 */
.ServiceProject__contentMain___1nzT9 .ServiceProject__contentTitle___3UORJ {
  color: #484848;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.ServiceProject__contentMain___1nzT9 .ServiceProject__showMore___JLuNW {
  margin-bottom: 62px;
  color: #ff8576;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.ServiceProject__contentMain___1nzT9 .ServiceProject__productList___155Oc {
  margin-bottom: 40px;
}
.ServiceProject__productItem___27v32 {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 200px;
  padding: 38px 0 38px 142px;
  border-bottom: 1px solid #efedec;
}
.ServiceProject__productItem___27v32:last-child {
  border-bottom: none;
}
.ServiceProject__productItem___27v32 .ServiceProject__productAvatar___gaTK3 {
  position: absolute;
  top: 40px;
  left: 0;
  width: 120px;
  height: 120px;
  border-radius: 6px;
  cursor: pointer;
}
.ServiceProject__productItem___27v32 .ServiceProject__btn___3a5Yn {
  position: absolute;
  top: 81px;
  right: 0;
  display: block;
  width: 84px;
  height: 38px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  background: #ff8576;
  border-radius: 6px;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ServiceProject__productItem___27v32 .ServiceProject__btn___3a5Yn:hover {
  opacity: 0.7;
}
.ServiceProject__productItem___27v32 .ServiceProject__title___3H5Cl {
  margin-bottom: 10px;
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
}
.ServiceProject__productItem___27v32 .ServiceProject__scoreBox___1M-cj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 12px;
}
.ServiceProject__productItem___27v32 .ServiceProject__scoreBox___1M-cj .ServiceProject__total___3Cb8T {
  margin-right: 10px;
  color: #ff8576;
  font-size: 18px;
  line-height: 23px;
}
.ServiceProject__productItem___27v32 .ServiceProject__scoreBox___1M-cj .ServiceProject__scoreName___1CEGf {
  margin-right: 6px;
  color: #635f5d;
  font-size: 14px;
  line-height: 20px;
}
.ServiceProject__productItem___27v32 .ServiceProject__scoreBox___1M-cj .ServiceProject__scoreNum___3RAJ5 {
  padding-bottom: 1px;
  color: #ff8576;
  font-size: 16px;
  line-height: 19px;
}
.ServiceProject__productItem___27v32 .ServiceProject__scoreBox___1M-cj .ServiceProject__scoreLine___rgsoB {
  width: 1px;
  height: 14px;
  margin: 0 12px 3px;
  background: #efedec;
}
.ServiceProject__productItem___27v32 .ServiceProject__labelBox___IzGco {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 13px;
}
.ServiceProject__productItem___27v32 .ServiceProject__labelBox___IzGco .ServiceProject__label___3hAuo {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 8px;
  padding: 3px 8px;
  color: #ff8576;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  border: 2px solid #fee5e2;
  border-radius: 3px;
}
.ServiceProject__productItem___27v32 .ServiceProject__msg___27T2_ {
  color: #ccc6c0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ServiceProject__noContent___LH1hA {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 100px 0 180px;
  color: #ccc6c0;
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}

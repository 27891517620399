/**
 * 通用颜色
 */
* {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  -moz-user-select: none;
       user-select: none;
  /* Standard syntax */
}
html {
  scroll-behavior: smooth;
}
.guide__content___2ZIor {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  color: #573013;
  background-color: #fd6;
  background-repeat: no-repeat;
  background-size: contain;
}
.guide__content___2ZIor .guide__brand___2PwBB {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 56px;
  height: 14px;
  background-size: cover;
}
.guide__content___2ZIor .guide__bg_1___mnKTL {
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
  height: 359px;
  background-size: cover;
}
.guide__content___2ZIor .guide__weight_500___1Nx1J {
  font-weight: 500;
}
.guide__content___2ZIor .guide__h1___2l1yP {
  font-weight: 600;
  font-size: 38px;
  line-height: 53px;
  text-align: center;
}
.guide__content___2ZIor .guide__h2___3P_pf {
  padding-top: 30px;
  font-size: 34px;
  line-height: 48px;
  text-align: center;
}
.guide__content___2ZIor .guide__title___7JsYz {
  font-size: 28px;
  line-height: 39px;
}
.guide__content___2ZIor .guide__text___72Aji {
  font-size: 16px;
  line-height: 22px;
}
.guide__content___2ZIor .guide__italic_title___39vMW {
  font-size: 46px;
  line-height: 64px;
  -webkit-transform: matrix(1, 0, -0.08, 1, 0, 0);
          transform: matrix(1, 0, -0.08, 1, 0, 0);
}
.guide__content___2ZIor .guide__btn___3zmPJ {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 55px;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  border-radius: 30px;
}
.guide__content___2ZIor .guide__xcx_btn___3uBhN {
  width: 100%;
  height: 55px;
  font-weight: 600;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  border-radius: 30px;
}
.guide__content___2ZIor .guide__btn___3zmPJ + .guide__xcx_btn___3uBhN,
.guide__content___2ZIor .guide__btn___3zmPJ + .guide__btn___3zmPJ {
  margin-top: 14px;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 70%;
  max-height: 454px;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH {
  position: relative;
  z-index: 1;
  margin: 0 0 0 35px;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH .guide__title___7JsYz {
  margin-bottom: 28px;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH .guide__text___72Aji {
  margin-top: 12px;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH .guide__text_1___8m6H9 {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH .guide__text_2___1aTHz {
  -webkit-animation-delay: 1800ms;
          animation-delay: 1800ms;
}
.guide__content___2ZIor .guide__init_inner___1ElR_ .guide__content_wrapper___31pmH .guide__text_3___l7oNC {
  -webkit-animation-delay: 2600ms;
          animation-delay: 2600ms;
}
.guide__content___2ZIor .guide__step_inner___xUkqX {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1079px;
  overflow: hidden;
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__content_wrapper___31pmH {
  padding-top: 60px;
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__content_wrapper___31pmH::after {
  display: block;
  clear: both;
  content: "";
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__img_wrapper___3D_Lx {
  position: relative;
  height: 870px;
  margin-top: 44px;
  overflow: hidden;
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__img_wrapper___3D_Lx > div {
  border-radius: 10px;
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__footer___2ZJg8 {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 375px;
  padding-top: 262px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(39.93%, #fd6), color-stop(81.63%, rgba(255, 221, 102, 0)));
  background: linear-gradient(0deg, #fd6 39.93%, rgba(255, 221, 102, 0) 81.63%);
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__italic_title___39vMW {
  float: right;
}
.guide__content___2ZIor .guide__step_inner___xUkqX .guide__title___7JsYz + .guide__italic_title___39vMW {
  margin-top: 2px;
}
.guide__content___2ZIor .guide__initPage___2oRW- {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__title___7JsYz {
  float: right;
  margin-right: 87px;
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__italic_title___39vMW {
  margin-right: 39px;
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__img_wrapper___3D_Lx > div {
  -webkit-box-shadow: 0 10px 30px rgba(138, 112, 21, 0.2);
          box-shadow: 0 10px 30px rgba(138, 112, 21, 0.2);
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__img_1___3EVq9 {
  float: left;
  width: 200px;
  height: 433px;
  margin-left: 23px;
  background-size: cover;
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__img_2___19yyW {
  position: absolute;
  top: 98px;
  right: -18px;
  width: 146px;
  height: 317px;
  background-size: cover;
}
.guide__content___2ZIor .guide__step_1___3zLtE .guide__img_3___TA01D {
  position: absolute;
  top: 282px;
  left: -9px;
  width: 322px;
  height: 697px;
  background-size: cover;
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__title___7JsYz {
  text-align: center;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__italic_title___39vMW {
  float: left;
  margin-left: 45px;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_wrapper___3D_Lx {
  top: -91px;
  height: 914px;
  margin: 0;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_wrapper___3D_Lx > div {
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_4___14m3_ {
  position: absolute;
  top: 320px;
  left: -125px;
  width: 193px;
  height: 418px;
  background-size: cover;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_5___C5JBG {
  position: absolute;
  top: 218px;
  left: 50px;
  width: 193px;
  height: 418px;
  background-size: cover;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_6___1I-7B {
  position: absolute;
  top: 116px;
  left: 225px;
  width: 193px;
  height: 418px;
  background-size: cover;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_7___3kY24 {
  position: absolute;
  top: 12px;
  left: 398px;
  width: 193px;
  height: 418px;
  background-size: cover;
  -webkit-animation-delay: 1500ms;
          animation-delay: 1500ms;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_8___3KhN4 {
  position: absolute;
  top: 657px;
  left: 149px;
  width: 193px;
  height: 418px;
  background-size: cover;
  -webkit-animation-delay: 2500ms;
          animation-delay: 2500ms;
}
.guide__content___2ZIor .guide__step_2___3dj8- .guide__img_9___2UhTD {
  position: absolute;
  top: 554px;
  left: 325px;
  width: 193px;
  height: 418px;
  background-size: cover;
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__title___7JsYz {
  float: right;
  margin-right: 53px;
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__italic_title___39vMW {
  margin-right: 55px;
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__img_wrapper___3D_Lx {
  height: 880px;
  margin-top: 34px;
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__img_wrapper___3D_Lx > div {
  -webkit-box-shadow: 0 10px 30px rgba(138, 112, 21, 0.2);
          box-shadow: 0 10px 30px rgba(138, 112, 21, 0.2);
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__img_10___3IugR {
  float: left;
  width: 200px;
  height: 435px;
  margin-left: 20px;
  background-size: cover;
}
.guide__content___2ZIor .guide__step_3___37FY0 .guide__img_11___MRzRc {
  position: absolute;
  top: 208px;
  right: 20px;
  width: 238px;
  height: 515px;
  background-size: cover;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
.guide__content___2ZIor .guide__step_4___973Vm {
  height: 100vh;
}
.guide__content___2ZIor .guide__step_4___973Vm .guide__qrcode_bg___2tBmI {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 191px);
  max-height: calc(106.13vw);
  background-size: cover;
}
.guide__content___2ZIor .guide__step_4___973Vm .guide__qrcode_content___1tYsW {
  position: relative;
  width: 204px;
  height: 204px;
  margin: 29% auto 0;
  background: #fff;
  border-radius: 100%;
}
.guide__content___2ZIor .guide__step_4___973Vm .guide__hand___3Oq-a {
  position: absolute;
  top: 35px;
  left: 50%;
  z-index: 1;
  width: 278px;
  height: 48px;
  margin-left: -139px;
  background-size: cover;
}
.guide__content___2ZIor .guide__step_4___973Vm .guide__qrcode___3epx8 {
  position: absolute;
  top: 0;
  left: 0;
  width: 206px;
  height: 206px;
  background-size: cover;
  border-radius: 100%;
  -webkit-box-shadow: 0 30px 50px rgba(138, 112, 21, 0.1);
          box-shadow: 0 30px 50px rgba(138, 112, 21, 0.1);
}
.guide__content___2ZIor .guide__btn_wrapper___2fNoM {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 30px;
}
.guide__content___2ZIor .guide__content_wrapper___31pmH + .guide__btn_wrapper___2fNoM {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.guide__content___2ZIor .guide__filled_btn___3q9P8 {
  color: #fd6;
  background: linear-gradient(198.85deg, rgba(255, 102, 102, 0.6) -38.93%, rgba(255, 102, 102, 0) 63.3%), #573013;
}
.guide__content___2ZIor .guide__blank_btn___p5rkJ {
  line-height: 22px;
  border: 2px solid #573013;
}
.guide__content___2ZIor .guide__layerMask___32khR {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(46, 46, 46, 0.5);
}
.guide__content___2ZIor .guide__layerInner___3CIn8 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 288px;
  height: 456px;
  margin: -236px 0 0 -144px;
  text-align: center;
  background-size: cover;
}
.guide__content___2ZIor .guide__layerClose___1Mz1_ {
  position: absolute;
  top: 34px;
  right: 14px;
  width: 34px;
  height: 34px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 13px 13px;
  border-radius: 100%;
  -webkit-box-shadow: 0 10px 30px rgba(138, 112, 21, 0.1);
          box-shadow: 0 10px 30px rgba(138, 112, 21, 0.1);
}
.guide__content___2ZIor .guide__layerTitle___3Kmm0 {
  margin-top: 56px;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.guide__content___2ZIor .guide__layerText___1k1Cr {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}
.guide__content___2ZIor .guide__layerText___1k1Cr span {
  position: relative;
}
.guide__content___2ZIor .guide__layerText___1k1Cr span::before {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13px;
  background: #ffa031;
  border-radius: 20px;
  opacity: 0.2;
  content: "";
}
.guide__content___2ZIor .guide__layerQrcode___3mFfo {
  width: 154px;
  height: 154px;
  margin: 28px auto 0;
  background-size: cover;
}
.guide__content___2ZIor .guide__upTip___3wras {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(16.8%, #fd6), color-stop(81.63%, rgba(255, 221, 102, 0)));
  background: linear-gradient(0deg, #fd6 16.8%, rgba(255, 221, 102, 0) 81.63%);
}
.guide__content___2ZIor .guide__upTipInner___3jmp3 {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 78px;
  padding: 18px 0;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  text-shadow: 0 4px 20px rgba(138, 112, 21, 0.2);
}
.guide__content___2ZIor .guide__upTipArrowWrapper___2ctUd {
  position: relative;
  height: 18px;
  margin-top: 6px;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.guide__content___2ZIor .guide__upTipArrow___2cgZG {
  position: absolute;
  top: -15px;
  left: 50%;
  width: 62px;
  height: 48px;
  margin-left: -31px;
  background-size: cover;
}
.guide__content___2ZIor .guide__upTipArrow___2cgZG:last-child {
  top: -6px;
}

/**
 * 通用颜色
 */
.Header__content___2ztx7 {
  width: 100%;
  min-width: 1032px;
}
.Header__content___2ztx7 .Header__contentMain___168Cn {
  position: relative;
  z-index: 777;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 1032px;
  height: 152px;
  margin: 0 auto;
}
.Header__content___2ztx7 .Header__contentMain___168Cn .Header__logo___2HMBK {
  width: 248px;
  height: 42px;
  margin-left: 2px;
  text-indent: -99em;
  background: url(/_next/static/images/logo_header_index-d65c7f6ce154f85d2305703653978a50.png);
  background-size: 100% 100%;
}
.Header__showCityBox___1QTSS {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 34px;
  margin-top: 6px;
  margin-left: 40px;
  padding: 8px 20px 7px 8px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  cursor: pointer;
}
.Header__showCityBox___1QTSS::after {
  position: absolute;
  top: 13px;
  right: 7px;
  width: 7px;
  height: 5px;
  margin: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPCAYAAAALWoRrAAAAYklEQVR4AbXQ0Q0AERAE0C1FKVeaTpUyR4ggsZhlEvyYF1bkRQC4uHw6xZDOiVtATmDhAlanRSl4ACvqWFjtMvBW5wQ+esTOZeZXagmG+c/KHw0qMEzgAubBCWwHB9hfA5n8pbCyHsugigUAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  content: "";
}
.Header__showCityBox___1QTSS .Header__currentCity___3xigK {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.Header__showCityBox___1QTSS .Header__hoverBox___ghQ5r {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 111;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 145px;
  padding: 24px;
  color: #635f5d;
  background: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 20px rgba(72, 72, 72, 0.1);
          box-shadow: 0 10px 20px rgba(72, 72, 72, 0.1);
  opacity: 0;
}
.Header__showCityBox___1QTSS .Header__hoverBox___ghQ5r .Header__currentCityHover___bYgGY {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.Header__showCityBox___1QTSS .Header__hoverBox___ghQ5r .Header__cityItem___302at {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  background: none;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.Header__showCityBox___1QTSS .Header__hoverBox___ghQ5r .Header__cityItem___302at:hover {
  color: #ff8576;
}
.Header__showCityBox___1QTSS .Header__hoverBox___ghQ5r .Header__selected___k8ZUq {
  color: #ff8576;
  font-weight: 500;
}
.Header__active___1d0fY:hover {
  overflow: visible;
}
.Header__active___1d0fY:hover .Header__hoverBox___ghQ5r {
  opacity: 1;
}
.Header__left___2trRl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Header__rightItem___1LFlE {
  position: relative;
  display: inline-block;
  margin-left: 14px;
  padding-left: 14px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;
}
.Header__rightItem___1LFlE::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 13px;
  margin: auto;
  background: #ffffff;
  content: "";
}
.Header__rightItem___1LFlE:first-child::before {
  display: none;
}

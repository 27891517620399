/**
 * 移动端通用变量
 */
.Choice__choice_wrapper___1CCFr {
  height: calc(100vh - ((100vw - 32px) * 0.95 + 110px));
  min-height: 196px;
  max-height: 251px;
}
.Choice__choice_wrapper___1CCFr .Choice__content___3PVxK {
  position: relative;
  top: 50%;
  left: 0;
  height: 172px;
  margin-top: -86px;
  padding: 0 16px;
  text-align: center;
}
.Choice__choice_wrapper___1CCFr .Choice__circle___3Ex9U {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 104px;
  height: 104px;
  margin: 0 auto;
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  line-height: 104px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff8761), to(#ff6f61));
  background: linear-gradient(180deg, #ff8761 0%, #ff6f61 100%);
  border-radius: 100%;
  -webkit-box-shadow: 0 10px 20px rgba(84, 67, 165, 0.5);
          box-shadow: 0 10px 20px rgba(84, 67, 165, 0.5);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Choice__choice_wrapper___1CCFr .Choice__circle___3Ex9U.Choice__liked___3Cj4J {
  color: rgba(255, 255, 255, 0.7);
  background: #ae5b8c;
  -webkit-box-shadow: 0 10px 20px rgba(84, 67, 165, 0.5);
          box-shadow: 0 10px 20px rgba(84, 67, 165, 0.5);
}
.Choice__choice_wrapper___1CCFr .Choice__count___1iaVn {
  margin-top: 10px;
  color: #fff;
  font-weight: 500;
  font-weight: 15px;
  line-height: 18px;
  text-align: center;
}
.Choice__choice_wrapper___1CCFr .Choice__share___3J0oc {
  display: block;
  margin-top: 22px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.5;
}
.Choice__choice_wrapper___1CCFr .Choice__share___3J0oc::before {
  display: inline-block;
  -webkit-transform: scale(0.917);
          transform: scale(0.917);
  content: attr(name);
}
.Choice__choice_wrapper___1CCFr .Choice__share___3J0oc::after {
  position: relative;
  top: 1px;
  left: -2px;
  display: inline-block;
  width: 6px;
  height: 10px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAeCAYAAAAhDE4sAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABfSURBVHgB7dZBCsAgDETRoSfKTeMNmpumCkKLVMHE5Xxw6WPcCfTcXdpBpgqovyki9SVjN3abQGHMiBEj9pNc2MuRrT1hsqacQIwIkQ+iJxBJIwvIEKleLGlkWBb+RDx3i8he0S1FxAAAAABJRU5ErkJggg==");
  background-size: cover;
  content: "";
}

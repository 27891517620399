/**
 * 通用颜色
 */
.join__content___2jHw4 {
  min-height: 100vh;
  background-image: url(/_next/static/images/header_bg-69fbf366e23cf61826fd70c0fd63e812.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 375px 312px;
}
.join__content___2jHw4 .join__headerDesc___3-3hk {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 32px;
  padding: 0 43px;
  color: #74706c;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
}
.join__content___2jHw4 .join__headerDesc___3-3hk::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;
  width: 9px;
  height: 1px;
  margin: auto;
  background: #b0a9a3;
  content: "";
}
.join__content___2jHw4 .join__headerDesc___3-3hk::after {
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  width: 9px;
  height: 1px;
  margin: auto;
  background: #b0a9a3;
  content: "";
}
.join__user___1_bop {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 166px;
  padding: 56px 90px 56px 98px;
}
.join__user___1_bop .join__avatar___-hgGu {
  position: absolute;
  top: 50px;
  left: 24px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.join__user___1_bop .join__name___1LsYk {
  margin-bottom: 4px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
.join__user___1_bop .join__info___2smMA {
  color: #918d89;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.join__list___2GXvV {
  padding: 0 20px 64px;
}
.join__list___2GXvV .join__group___dP1ac {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 16px;
  padding: 32px 44px 36px;
  background: #ffffff;
  border: 1px solid #efedec;
  border-radius: 12px;
  -webkit-box-shadow: 0 3px 3px #f5f4f4;
          box-shadow: 0 3px 3px #f5f4f4;
}
.join__list___2GXvV .join__group___dP1ac:last-child {
  margin-bottom: 0;
}
.join__list___2GXvV .join__group___dP1ac .join__avatarList___O_Vet {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 72px;
  height: 72px;
  margin-bottom: 14px;
  padding: 4px;
  overflow: hidden;
  background: #f5f4f4;
  border-radius: 4px;
}
.join__list___2GXvV .join__group___dP1ac .join__avatarList___O_Vet .join__headimg___3sKNm {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  border-radius: 1.5px;
}
.join__list___2GXvV .join__group___dP1ac .join__title___14Wpy {
  margin-bottom: 7px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
.join__list___2GXvV .join__group___dP1ac .join__desc___WLV6Z {
  margin-bottom: 26px;
  color: #74706c;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.join__list___2GXvV .join__group___dP1ac .join__btn___2aiGl {
  width: 247px;
  height: 45px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  background: #ff6f61;
  border: none;
  border-radius: 6px;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.join__list___2GXvV .join__group___dP1ac .join__btn___2aiGl:hover {
  opacity: 0.7;
}
.join__modal___2OW25 {
  position: relative;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.join__modal___2OW25 .join__mask___3mEcX {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(46, 46, 46, 0.5);
  -ms-touch-action: none;
      touch-action: none;
}
.join__modal___2OW25 .join__mask___3mEcX .join__maskContent___2Yjk6 {
  position: relative;
}
.join__modal___2OW25 .join__mask___3mEcX .join__maskContent___2Yjk6 .join__close___2rFkm {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  width: 28px;
  height: 28px;
  padding: 24px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUBAMAAADuRQ3yAAAAFVBMVEUAAAD///////////////////////9Iz20EAAAAB3RSTlMAZnN/gL/MFl0LpgAAATZJREFUSMft1jtuxDAMBFDtGtunSZ9me1eqdYQkV7Ax9z9CCguCRHFGrIItVp1s4sEfcqCU3usl1udv9O4dKLxyAz7a5gGcvDQD330pZzf0pTcINgP4GreFo4fZnxzd7YUSQQU7oZSdUco6KGE9lLAu6rI+6rIEdViGOixFJ5ajEytQwyrUsBIdWI0O7ALt2BXasUu0sWu0sQG0shG0siH0YkPoxcbQyh6hsN3C6FVaUvgBzhR/rZLCH+uMoUcOsRnAvkXY+vcjbP37Aba11JptLbVkuz5dsV2fLtih+TU7NL9kzUQp1kyUYKcx5ew0ppR1Zp+xzuwT1g0Un3UDxWVJSnksSSmHpdE3szT6JlbkqWVFnhr2rqIvw5wJefSNB72HzNNszoS7HKPuTPj8UXOs777X/60/9/TEkVLcBjwAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 28px;
}
.join__modal___2OW25 .join__container___11fF7 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 300px;
  max-width: 600px;
  height: 392px;
  margin: auto;
  padding: 32px 28px 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 14px;
  -ms-touch-action: none;
      touch-action: none;
}
.join__modal___2OW25 .join__container___11fF7 .join__header___3Wzih {
  position: relative;
  min-height: 60px;
  margin-bottom: 24px;
  padding-left: 74px;
}
.join__modal___2OW25 .join__container___11fF7 .join__header___3Wzih .join__avatarList___O_Vet {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 4px;
  overflow: hidden;
  background: #f5f4f4;
  border-radius: 4px;
}
.join__modal___2OW25 .join__container___11fF7 .join__header___3Wzih .join__avatarList___O_Vet .join__headimg___3sKNm {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  border-radius: 1.5px;
}
.join__modal___2OW25 .join__container___11fF7 .join__header___3Wzih .join__title___14Wpy {
  margin-bottom: 3px;
  color: #2e2e2e;
  font-size: 15px;
  line-height: 20px;
}
.join__modal___2OW25 .join__container___11fF7 .join__header___3Wzih .join__desc___WLV6Z {
  color: #b0a9a3;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
}
.join__modal___2OW25 .join__container___11fF7 .join__qrcode___bOXnO {
  display: block;
  width: 244px;
  height: 244px;
  margin: auto;
}
.join__modal___2OW25 .join__show___1wx-g {
  z-index: 999;
}
.join__show___1wx-g {
  z-index: 999;
  opacity: 1;
}

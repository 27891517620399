.Like__like___3Oa86 {
  position: relative;
  display: inline-block;
}
.Like__like___3Oa86,
.Like__like___3Oa86 img {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Like__count___3OXLG {
  display: inline-block;
  width: 20px;
  color: #918d89;
  font-size: 16px;
  line-height: 20px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

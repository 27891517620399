/**
 * 移动端通用变量
 */
.OrdersCard__content___3ukdU {
  position: relative;
  margin-bottom: 40px;
}
.OrdersCard__content___3ukdU::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #efedec;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  content: "";
}
.OrdersCard__content___3ukdU .OrdersCard__cardTitle___2jzcL {
  color: #484848;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
.OrdersCard__content___3ukdU .OrdersCard__hasMore___3Auxm {
  padding: 4px 0 40px;
}
.OrdersCard__content___3ukdU .OrdersCard__hasMore___3Auxm p {
  color: #918d89;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
}
.OrdersCard__box___33eyA {
  position: relative;
  display: block;
  padding: 26px 0;
  overflow: hidden;
}
.OrdersCard__box___33eyA:first-child::before {
  display: none;
}
.OrdersCard__box___33eyA .OrdersCard__title___163b3 {
  position: relative;
  padding: 0 22px 6px 0;
  overflow: hidden;
  color: #484848;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.OrdersCard__box___33eyA .OrdersCard__title___163b3 span {
  color: #ff8576;
}
.OrdersCard__box___33eyA .OrdersCard__msg___11Hoq {
  overflow: hidden;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.OrdersCard__box___33eyA .OrdersCard__msg___11Hoq span {
  color: #ff8576;
}
.OrdersCard__box___33eyA .OrdersCard__label___1kzS2 {
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 60%;
  height: 22px;
  margin-top: 6px;
  padding: 0 6px;
  overflow: hidden;
  color: #ff8576;
  font-weight: bold;
  font-size: 8px;
  line-height: 22px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  background: #ffecea;
  border-radius: 5px;
}
.OrdersCard__box___33eyA .OrdersCard__time___2z3RX {
  max-width: 37%;
  padding-top: 8px;
  overflow: hidden;
  color: #b0a9a3;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.OrdersCard__box___33eyA .OrdersCard__priority___2MI4P {
  position: absolute;
  right: 0;
  bottom: 26px;
  overflow: hidden;
  color: #ff8576;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.OrdersCard__arrowIcon___3Gbc- {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  height: 12px;
  margin: auto;
}

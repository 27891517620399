/**
 * 通用颜色
 */
.LayerC__layer___26Ags {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.LayerC__layer___26Ags .LayerC__clear___2fYzD {
  background: none;
  border: none;
  outline: none;
}
.LayerC__layer___26Ags .LayerC__close___3Vzbo {
  position: fixed;
  top: 80px;
  right: 80px;
  width: 38px;
  height: 38px;
  padding: 20px;
  cursor: pointer;
}
.LayerC__layer___26Ags .LayerC__main___3VUY0 {
  background: #000;
}
.LayerC__show___NihYE {
  z-index: 999;
  opacity: 1;
}

/**
 * 通用颜色
 */
.Breadcrumb__content___Zu7Jo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 60px;
}
.Breadcrumb__content___Zu7Jo .Breadcrumb__item___FBM3m {
  position: relative;
  padding-right: 24px;
  color: #b0a9a3;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  outline: none;
}
.Breadcrumb__content___Zu7Jo .Breadcrumb__item___FBM3m::after {
  position: absolute;
  top: 4px;
  right: 6px;
  width: 12px;
  height: 12px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkBAMAAAATLoWrAAAAElBMVEUAAADKxb/Nxb/Nx8HLxb/MxsB2YTk7AAAABXRSTlMAYGB/gPSF3IsAAAA5SURBVCjPY2CgNmASwBBSDcYUCjVAF2IJxVTmOtSUBaILMWMKmYY6YCgKGQqKVDEUMaiGEJMAqAMAi2ASaaVlNxYAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  content: "";
}
.Breadcrumb__content___Zu7Jo .Breadcrumb__item___FBM3m:last-child {
  color: #74706c;
}
.Breadcrumb__content___Zu7Jo .Breadcrumb__item___FBM3m:last-child::after {
  display: none;
}
.Breadcrumb__content___Zu7Jo a:hover {
  color: #74706c;
}

.Player__wrap___1IYRc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 58px;
  padding: 0 20px;
}
.Player__playerIcon___mvFqW {
  display: block;
  width: 24px;
  height: 24px;
}
.Player__progress___nXL41 {
  min-width: 80px;
  color: #918d89;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
.Player__divid___355oZ {
  padding: 0 3px;
  font-size: normal;
}
.Player__audio___2UxEN {
  display: none;
}
.Player__iconbox___3mAlE,
.Player__iconbox___3mAlE img {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Player__audioName___1t4ui {
  position: relative;
  width: 196px;
  height: 58px;
  margin: 0 auto;
  line-height: 58px;
}
.Player__audioName___1t4ui.Player__play___2l7A8::after,
.Player__audioName___1t4ui.Player__play___2l7A8::before {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 100%;
  content: " ";
}
.Player__audioName___1t4ui.Player__play___2l7A8::before {
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.Player__audioName___1t4ui.Player__play___2l7A8::after {
  top: 0;
  right: 0;
  background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
}
.Player__audioName___1t4ui.Player__play___2l7A8 .Player__audioText___1wqpd {
  -webkit-animation: Player__moving___1RcJq 10s infinite linear;
          animation: Player__moving___1RcJq 10s infinite linear;
}
.Player__audioText___1wqpd {
  color: #2e2e2e;
  font-weight: 500;
  font-size: 14px;
}
.Player__audioText___1wqpd span:first-child {
  display: inline-block;
  width: 360px;
}
@-webkit-keyframes Player__moving___1RcJq {
  0% {
    left: 0;
  }
  100% {
    -webkit-transform: translateX(-360px);
            transform: translateX(-360px);
  }
}
@keyframes Player__moving___1RcJq {
  0% {
    left: 0;
  }
  100% {
    -webkit-transform: translateX(-360px);
            transform: translateX(-360px);
  }
}

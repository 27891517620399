/**
 * 移动端通用变量
 */
.learning__learning_content___2B-Zr {
  position: relative;
  min-width: 320px;
  height: 100vh;
  overflow: hidden;
  background-color: #634ccb;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.learning__learning_content___2B-Zr .learning__flex_box___1RSHw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 61px);
  margin-top: 18px;
}
.learning__learning_content___2B-Zr .learning__img_wrapper___vwwxr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 166px);
}

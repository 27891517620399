/**
 * 通用颜色
 */
.registrationSuccess__main___16JHY {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}
.registrationSuccess__main___16JHY .registrationSuccess__check___2QmTA {
  width: 52px;
  height: 52px;
}
.registrationSuccess__main___16JHY .registrationSuccess__check___2QmTA img {
  width: 100%;
}
.registrationSuccess__main___16JHY .registrationSuccess__title___2ZVYc {
  color: #2e2e2e;
  text-align: center;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  font-style: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: normal;
}
.registrationSuccess__main___16JHY .registrationSuccess__content___2h5o- {
  color: #2e2e2e;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: normal;
}
.registrationSuccess__main___16JHY .registrationSuccess__qrcode___20NCQ {
  width: 170px;
  height: 170px;
  margin-top: 20px;
}
.registrationSuccess__main___16JHY .registrationSuccess__qrcode___20NCQ img {
  width: 100%;
}

/**
 * 通用颜色
 */
.Recommend__contentMain___1mfoE {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 262px;
  overflow: hidden;
}
.Recommend__contentMain___1mfoE .Recommend__title___of3BT {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Recommend__contentMain___1mfoE .Recommend__item___3c6Bw {
  margin-bottom: 40px;
  cursor: pointer;
}
.Recommend__contentMain___1mfoE .Recommend__item___3c6Bw .Recommend__avatar___3aZQV {
  width: 262px;
  height: 180px;
  margin-bottom: 16px;
  border-radius: 6px;
}
.Recommend__contentMain___1mfoE .Recommend__info___2JLCn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.Recommend__contentMain___1mfoE .Recommend__info___2JLCn .Recommend__name___7kSb0 {
  width: 225px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Recommend__contentMain___1mfoE .Recommend__info___2JLCn .Recommend__score___KO5EV {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  overflow: hidden;
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  background: #ff8576;
  border-radius: 6px;
}

/**
 * 移动端通用变量
 */
.Card__learning_wrapper___2O1_6 {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 0 auto;
  background: #fff;
  border-radius: 21px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}
.Card__learning_wrapper___2O1_6 li:not(.Card__prev___h3RDo):not(.Card__next___3mtkv) {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: calc(100%);
  padding: 34px 0;
  background: #fff;
  border-radius: 21px;
}
.Card__learning_wrapper___2O1_6 li:not(.Card__prev___h3RDo):not(.Card__next___3mtkv).Card__second___T7yz3 {
  top: 8px;
  left: 7px;
  height: calc(100% - 16px);
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8))), #634ccb;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #634ccb;
  -webkit-box-shadow: 0 10px 20px rgba(84, 67, 165, 0.2);
          box-shadow: 0 10px 20px rgba(84, 67, 165, 0.2);
}
.Card__learning_wrapper___2O1_6 li:not(.Card__prev___h3RDo):not(.Card__next___3mtkv).Card__third___39ygq {
  top: 16px;
  left: 14px;
  height: calc(100% - 32px);
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.3))), #634ccb;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #634ccb;
  -webkit-box-shadow: 0 10px 20px rgba(84, 67, 165, 0.2);
          box-shadow: 0 10px 20px rgba(84, 67, 165, 0.2);
}
.Card__learning_wrapper___2O1_6 li.Card__prev___h3RDo,
.Card__learning_wrapper___2O1_6 li.Card__next___3mtkv {
  position: absolute;
  top: 0;
  z-index: 4;
  width: 30px;
  height: calc(100%);
}
.Card__learning_wrapper___2O1_6 li.Card__prev___h3RDo::after,
.Card__learning_wrapper___2O1_6 li.Card__next___3mtkv::after {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 18px;
  content: "";
}
.Card__learning_wrapper___2O1_6 li.Card__prev___h3RDo {
  left: -30px;
}
.Card__learning_wrapper___2O1_6 li.Card__prev___h3RDo::after {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA2CAYAAADQzyn5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFiSURBVHgBxdhNTsMwEAXgGYtyRsKGsG8lOAFwAmjDnnTTcDzWBNkkkUCtGjv+eTN9qyiO9ElJZM8Mk0K6pn9yRA/jtWFqb9arRybhdLufD2JXH9+z1r6IwnPomAH9MiQUH/oXEXgJtUR7+KteQoncvtpc11A4Fh2vYHAKCoNTUQicgxbDuWgRXIJmw6VoFoxAk2EUmgQj0WgYjUbBEugiLIUGYUnUC0ujs7AGegZroSewJvoPa6NjzOeuf9VGJ9gy1aSMTnBwlY0joRhn7da76lzdvQ/fXyDTz3VovlsmvvM/xW21vronNHwJ/GQD0cTPtkwtfPaQ0MC9x6I0HiwEJPHF0kcKjyr2JPDo8haNJxX0SDy5hUHhWU0bAs9uU0vxosa8BC8eReTikOFLDg4bN6XiMDgVh8IpOByOwUXn1SFcdF59OzQCbmgIfOticAgXmVfP5dD0b+Roeu3MtK02q+df62g8EZflwQMAAAAASUVORK5CYII=");
  background-size: cover;
}
.Card__learning_wrapper___2O1_6 li.Card__next___3mtkv {
  right: -30px;
}
.Card__learning_wrapper___2O1_6 li.Card__next___3mtkv::after {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA2CAYAAADQzyn5AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBxdhLTsMwEAbg+S3KGcmKsG8lOAFwAmjLnnRDOB5rgmziQN9x/Jpx/0XkzFj6pGRjD6hPu+4eDdG9XStQczOfPZBw8LnqXgz+0H0VTTW/uiPBKA2qz6rG1O3bzzsJRjk7wrgyWi/pAjjs42P93YBw697F/8+xXZTGcfhSEsdpoRSOsWIJHK6GNI6ppiQO3wYp3AtL4UGwBB4Mc+NRMCceDXPhSTAHngzn4llwDp4Np+IscArOBsfirHAMzg6H4FrrZxHYh/folyKhwCgz1ReB21V/FoepXX1NtGH/1D60vyVsqsV1zQqHonbFBsegbHAsygKnoNlwKpoF56DJcC6aBHOg0TAXGgVzosEwNxoES6BeWAqdhCVRJyyNjsIl0DO4FHoEl0R3cGnURtl5dWl0gEfn1cLoANMF0AEen1fLojb/8+rulQwN1w2AltVi9kTC+QWvoT29LZlONwAAAABJRU5ErkJggg==");
  background-size: cover;
}
.Card__inner___2Jnik {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center;
}
.Card__user___2p5wj {
  position: relative;
  display: inline-block;
  max-width: 86px;
  height: 34px;
  padding-left: 42px;
  overflow: hidden;
  color: #74706c;
  font-size: 12px;
  line-height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Card__user___2p5wj img {
  position: absolute;
  left: 0;
  border-radius: 100%;
}
.Card__content_wrapper___3iqTX {
  margin: 16px 30px 0;
  color: #484848;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
  word-break: break-all;
}
.Card__content_wrapper___3iqTX .Card__before___2XiIg::before,
.Card__content_wrapper___3iqTX .Card__after___BcMg8::before {
  width: 30px;
  height: 18px;
  content: "";
}
.Card__content_wrapper___3iqTX .Card__before___2XiIg::before {
  float: left;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAA2CAMAAABwQH4RAAAARVBMVEUAAADv7e3v7uzv7+fv7e3v5+fu7ezv5+Pv7ezv6+vv7u3v7erv6+vv7evs7Orr6+vv7+3v7Oru7Ozv7uzv7+3v7u3v7ewW3oUDAAAAFnRSTlMA378g7yCQEJ+AzzBAcGBAf2BQr2/fKYUyOQAAAZtJREFUWMOVltuSgzAMQxsTQqDlUtrq/z91d2cyZSBZ29KzjsYoF3L7V+smEVGWmy4eWAVFyRe8f4HNCj70UIwsMKTiMcbmgTXgpKzGHoAd/cZFs5JKAQlX3fUyshMYpDIGPXlyAn1txFNL7oMFHEZiaAYYArihexM4ajNnsAFpOV9oqKt9PLC1jGPt44E37K+zgVdrRWKraKWOHl7gWGvnFVYA+xwmomgOWFHkvUwXJdmuY7opCt4137QVcQODsthmMg9kzcgB9gxxSdMH+Mh49wHhF4h/wNyfZ9BU3hUEsJ7OoarcK0OrwAJLoVhpQGAqnhqf4MzuAY/V7qPe5Ds8kroPG0iopF+C2Q0IqLEZIIAcO7oBOJVKshsY3c6JjRa3M7LRwd01SjIBvNhocQOJLIQAHuA2NgNEbvMxwOhz7t/o0X3GhujbSkUUMHucueSSgMBWV2IZoH4a2m+uzg90wds0CdjWeNRRA9Y7uxP3X5cHRq/RBsIV6HLbKFUbBKB549yM5YHuea5wGgclmQWGPckEIIY8VyVTwA+vy/ZS6VwClgAAAABJRU5ErkJggg==);
  background-size: cover;
}
.Card__content_wrapper___3iqTX .Card__after___BcMg8::before {
  float: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAA2CAMAAABwQH4RAAAAQlBMVEUAAADv7e3w6Ojv7e3v7ezv7ezv7+fu6+vv6+fv7ezu7ezv7uzv7e3v7uzv7evv6+rs7Ort7Ovu7Ozv7u3n5+fv7ezXasDDAAAAFXRSTlMA3yDvv4AgQBCfkHDPr2AwYKBQzyApwLWbAAAB3ElEQVRYw5WX0ZbbIBBDZ8wQMNiOk1T//6s923o76QaDfJ+RjpAHQuSdX3XWAECtPCchSHX9EoSXxZTllFwN79g+cM8xcIIa8MGc5JzYEjTMJ0/cXeuChRTcFWdEafEMOCHU/50DztFG8A0d1jfBpOihd/nBHU4/jKJPcG+PQnlHOFQn81iQ/2YIGPLK4kwYY2QGnxNeUL3pERMV2glZJIGiyDc7QO6zgE1xsJICEQPHLgcBHIleOR/OGSRFQKKH9Q0kq4DlqrWKgmTyQjjELlrzWSRetX7Q1oleefDkv7uxR+AgB1CYSKJXHlT+ajBcvPteYHiKSFbu3P7jRlVy+7OU8Fb6Z9oLJL1n7nHhbN9bfFDbc25GX8KSjArtbMoLbtUUCEtpiZJ8ksqXQK3U0Nnl6JWxSocpNEKTzp6BFYSWIC+d4ugoURqs+EQ7obP2D4ETeyPKRsFdPqm4WEdkn+QTLtaxNwXsF0Hqjh05HTO5u/7DfGOLLpeLZne39Caa++b8RDvKRtmvOseWcyYz6NWrY2kKds7ZiWxmWToRyKYtk197zdIj0WNauZPiFPygks2FJAPWwZ9tJ9JlNN+rJXPVaZIxGxxLQs2SbULxGBk7mwWEpfg6SmDxXPAbTMjrowI0YA0AAAAASUVORK5CYII=);
  background-size: cover;
}
.Card__shadow___3jUXn {
  position: absolute;
  bottom: 34px;
  left: 0;
  width: 100%;
  height: 70px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

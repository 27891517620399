/**
 * 移动端通用变量
 */
.Choice__choice_wrapper___1cxIS {
  height: calc(100vh - ((100vw - 32px) * 0.95 + 110px));
  min-height: 164px;
  max-height: 228px;
}
.Choice__choice_wrapper___1cxIS .Choice__content___PXSEg {
  position: relative;
  top: 50%;
  left: 0;
  height: 100px;
  margin-top: -50px;
  padding: 0 16px;
  text-align: center;
}
.Choice__choice_wrapper___1cxIS .Choice__circle_wrapper___1Co2r {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 70px;
}
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s {
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 70px;
  height: 70px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 28px;
  margin: -14px 0 0 -15px;
  content: "";
}
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s.Choice__unlike___2-Uzc::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABUCAMAAAA1fNBAAAAAPFBMVEUAAAD////////////////////////////////////////////////////////////////////////////YSWgTAAAAE3RSTlMA3yDvEJBwYEAwv4DPr59QsKAfiHMblQAAArdJREFUWMPtmNty2zAMRAneL6LUVP//r+1UUdBiLUYk1Tfvk+OZHHKBFUFZvfXWf5DVwa+VaN93s+QSn6Hqray0C9Gq57Ya/bqY/UKj8BRcpb0t47v9e/B/pXp/47Fks/eI9E1wbkGq81vSfzoQ8i221doen9xVSRfnoxVV+2iz7dkq8vo1mVa/6YuWnP1YvrFPWxBbrS4kq1oq+yEnd7xK2+x/9RtAW2zxaF50jHwCwrfsjF+iyKouVdy2/htH/Nn3ngQGtu0+y3q0Sn/VOXSB2b2Vla5Wlser7m3L/zu+SFAfp3rlZEWkjbMiP7rR8aisRPHfdLa0j8uoKFBJhOi3+tFVFLuKPLgT/bMb7Y+JI1BFnQonehssNl0ulYbTp6yIxMbH4Xj6sG+MMmo2fdg3DPYynj4n7Mr0fc0j240Owq50UcbSxxEgcIHpG40Io4bThzJsl1GLWJpX69HCdpvpW/qwHAGPLnhpXm1k0jhw8Xj60IU/0WkyfehiG5q82Dd2gQdU6ePyqQEuMH3TaCsHFg2nL4mCQPrqcPpWvi9A+ubGo+YT4uH0xc9KapjEc+nTIXP3IX2D49Gm4LLBtyVGkYgQr9Z8sYa31a0x5BlNzcoeW5Uqr4Z8hDljvhmxKLO9HPIrN/pUbOYMweV0LtPmYgquGnD3ShGwi4v2clyizC00VRd0R+Ew2YjGF2uUJWh0xowgOt95lv5l06Zi+8aQ7p+N+oMLnL39SqQBu/CY3YC7vCznmz6n282jUZmz/TQ6wnEzicZI5sfQGEn3GBofOd+4cYyqwEMJ14JhZWBDQSbLjew0jMYTwDfOkEEl8/Ls9jA8Z9hVQxO8eohNQc6vqGbZJH8CtzTdRTzNc0jKRuLWTrOXxi/WsyqNwTmrSJLs1GMqBOTHpAvBDWxOeIekdbPqrS79AlWlfZR4GlONAAAAAElFTkSuQmCC);
  background-size: cover;
}
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s.Choice__like___x-3oZ {
  background: #fbb7b4;
  border-color: transparent;
  -webkit-box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
          box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
}
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s.Choice__like___x-3oZ::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABUCAMAAAA1fNBAAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMA3+9gIIAQnzBwz0C/kFCvoB+wj2afCeIAAALASURBVFjD7ZjdtuIgDIWbhN+Wts6R93/WWeswGiG1WGDu3LddbpLwJYDTV41aLGDEcFejjT3Gh+xQcxXii2CgN2HMhG6Us46lQI12ZoWxzoHM5G4xaRngvD3q6//VfVjYCsrqrqPCFs6TgUR3f6ElET4VyHSWIyZlHBvkinSXw0+ZUmuufV0YuayiItBPh2w9k1b807+HJD7MXKb2PZSJM9qhO2glvywCP7WQ17RUgay3xhM/48jbHXhs0QU8Dsfn/vtpnjEK7apuLYIW+B0LXM3ZnU0hJQyvnEH2tC/gzBvMJ/WgD08enK3Vz+3Up85LsYkSnyTYV785wyvWp6KudIXSWtOiJFb1Rg2NvZzOzp/pRMhzui7ZqFX0cLoufDMV1UZa+yUtfn0A8VTc8lTu8xNM7ZuP1puA1oUopZvn5SovjP3WVCAiZ0LTycoAQG0k/DSfTchJ8ChY9c5zZuqmL5mhNsXswfa7i8sWomzh5vvAnpkln3yAMPkXtTJcbJ195bUvKu82KLL3TF8v2Nz3/BLgtZtm35z1vZcdNDeDDe/6voc+k/2Syt6DVvo4LtH3zGaS67PmHPrpkyjjO/p0R63H0+eYLaaPhtBH+bG6FvSZDvps7kWcfUGfuVxq5OKK+vTRR0VIqsz+1kofP1OG06fFjR+K7KmRvkWCtRfZuzb6FHDQxakj6YMGZ051GH0LHP2ftpXZz5fpM2kHuRyj6DMa30FVZv8MwX9SYx8wCudO+oyjO9uKUJroU460lW905DrX6UOTh6k2f98B46GCOr3zcPGT7q9hQjxR4JDP6FMvv7BWhCmFqzDOrdQj7z1eEAbhKxEB91tNi1U7fqOTq09DKYhS/H6gTX02Wg4C1Up6Q7CanLl4L2Yx0gTPMPfVb2rMH/e3FJrbiDjMRnkQkA6TooAxwmz9Zqavvvrf+guBlIMvPWCvoQAAAABJRU5ErkJggg==);
  background-size: cover;
}
.Choice__choice_wrapper___1cxIS .Choice__circle_text_wrapper___2q2Rm {
  height: 20px;
  margin-top: 10px;
  font-size: 14px;
}
.Choice__choice_wrapper___1cxIS .Choice__circle_text___dMqmG {
  display: inline-block;
  width: 70px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.Choice__choice_wrapper___1cxIS .Choice__circle_text___dMqmG + .Choice__circle_text___dMqmG,
.Choice__choice_wrapper___1cxIS .Choice__circle___2X54s + .Choice__circle___2X54s {
  margin-left: 75px;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_wrapper___cI65N {
  position: absolute;
  top: 0;
  width: calc(100% - 32px);
  height: 58px;
}
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0 {
  position: relative;
  z-index: -1;
  display: inline-block;
  background: #fff;
}
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0.Choice__bar_active___1ChnM.Choice__unlike___2-Uzc,
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0.Choice__bar_active___1ChnM.Choice__like___x-3oZ {
  height: 26px;
  margin-top: 0;
  background: #fcec60;
}
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0.Choice__unlike___2-Uzc {
  float: left;
  height: 18px;
  margin-top: 4px;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
}
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0.Choice__like___x-3oZ {
  float: right;
  height: 18px;
  margin-top: 4px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  -webkit-box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
          box-shadow: 0 10px 20px rgba(255, 133, 118, 0.15);
}
.Choice__choice_wrapper___1cxIS .Choice__bar___1BVs0.Choice__all_radius___EsKOj {
  border-radius: 21px;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_text_wrapper___3-7dg {
  margin-top: 39px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_text___1vOom {
  display: inline-block;
  width: 50%;
  color: rgba(255, 255, 255, 0.7);
}
.Choice__choice_wrapper___1cxIS .Choice__bar_text___1vOom.Choice__bar_text_active___1kbWy {
  color: #fff;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_text___1vOom:first-child {
  text-align: left;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_text___1vOom:last-child {
  text-align: right;
}
.Choice__choice_wrapper___1cxIS .Choice__bar_btn___hadO3 {
  position: relative;
  top: 84px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 34.1%;
  height: 46px;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  line-height: 44px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 39px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

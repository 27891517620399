/**
 * 通用颜色
 */
.Recommend__contentMain___1TiTl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 262px;
  overflow: hidden;
}
.Recommend__contentMain___1TiTl .Recommend__title___2uQj3 {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.Recommend__contentMain___1TiTl .Recommend__item___19Pz- {
  margin-bottom: 40px;
  cursor: pointer;
}
.Recommend__contentMain___1TiTl .Recommend__item___19Pz- .Recommend__avatar___17fp4 {
  width: 262px;
  height: 180px;
  margin-bottom: 16px;
  border-radius: 6px;
}
.Recommend__contentMain___1TiTl .Recommend__item___19Pz- .Recommend__name___wWlxQ {
  margin-bottom: 4px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Recommend__contentMain___1TiTl .Recommend__price___2JW-g {
  color: #ff8576;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.Recommend__contentMain___1TiTl .Recommend__price___2JW-g span {
  margin-right: 2px;
  font-family: "Arial", "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans", sans-serif;
}
